import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import BI_config from '@/utils/config'

Vue.use(Router)
const router = new Router({
  mode: 'history',
  routes: [
    {
      path: `${BI_config.dashboardProxy}`,
      name: 'appinner',
      component: () => import('@/views/appinner.vue'),
      redirect: `${BI_config.dashboardProxy}/list`,
      children: [
        {
          path: '',
          name: 'Layout',
          component: () => import('@/views/mp/Layout.vue'),
          children: [
            {
              path: 'Index/:projectID?',
              name: 'bi',
              component: () => import('@/views/dashboard/dashboard.vue'),
              meta: { name: 'dashboard', code: 'dashboard' },
            },
            {
              path: 'Index/mobile/:projectID?',
              name: 'bi',
              component: () => import('@/views/mobilev2/index.vue'),
              meta: { name: 'dashboard', code: 'dashboard' },
            },
          ],
        },
        {
          path: '',
          name: 'sharedLayout',
          component: () => import('@/views/crm'),
          children: [
            {
              path: 'me/:mallID',
              name: 'me',
              component: () => import('@/views/dashboard/dashboard.vue'),
              meta: { name: 'dashboard', code: 'dashboard' },
            },
            {
              path: 'crmIndex/:projectID',
              name: 'crm',
              component: () => import('@/views/dashboard/dashboard.vue'),
              meta: { name: 'dashboard', code: 'dashboard' },
            },
            {
              path: 'sale/:mallID',
              name: 'sale',
              component: () => import('@/views/dashboard/dashboard.vue'),
              meta: { name: 'dashboard', code: 'dashboard' },
            },
          ],
        },
        { path: 'list', name: 'List', component: () => import('@/views/mp/List.vue'), meta: { name: 'dashboard', code: 'dashboard' } },
        { path: 'me', name: 'ME', component: () => import('@/views/crm/meList.vue'), meta: { name: 'dashboard', code: 'dashboard' } },
        {
          path: 'MobileList',
          name: 'MobileList',
          component: () => import('@/views/Mobile/list.vue'),
          meta: { name: 'dashboard', code: 'dashboard', clienttype: 'mobile' },
        },
        {
          path: 'Mobile/:projectID',
          name: 'Mobile',
          component: () => import('@/views/Mobile/index.vue'),
          meta: { name: 'dashboard', code: 'dashboard', clienttype: 'mobile' },
        },
      ],
    },
    //数据报告
    {
      path: `${BI_config.dashboardProxy}/`,
      name: 'reportLayout',
      component: () => import('@/views/Report/Layout.vue'),
      children: [
        {
          path: 'reportList/:projectID',
          name: 'reportList',
          component: () => import('@/views/Report/list/index.vue'),
          meta: { name: 'ppt', code: 'ppt' },
        },
        {
          path: 'reportPages/:projectID/:pptID/:slideID?',
          name: 'reportPages',
          component: () => import('@/views/Report/pages/index.vue'),
          meta: { name: 'ppt', code: 'ppt' },
        },
      ],
    },
    // 404
    {
      path: `${BI_config.dashboardProxy}/404`,
      component: () => import('@/views/error-page/404'),
      hidden: true,
    },
    {
      path: `${BI_config.dashboardProxy}/m404`,
      component: () => import('@/views/error-page/m404'),
      hidden: true,
      meta: { clienttype: 'mobile' },
    },
  ],
})

const VueRouterPush = Router.prototype.push
Router.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch(err => err)
}
router.beforeEach(({ meta, path, fullPath }, from, next) => {
  // 如果是跳转
  if (from.hash) {
    store.commit('setFullPath', fullPath)
    store.commit('clearToken') // 取消请求
  }
  next()
})

export default router
