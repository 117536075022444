import request from '@/utils/request2'

// 页面埋点执行方法
export function trackView({ system, module, pageName, path }) {
  const systemlist = {
    bi: 'BI',
    me: 'ME',
    crm: '会员统计',
    sale: '商家销售',
  }
  const params = {
    system: systemlist[system],
    module,
    pageName,
    pageUrl: window.location.host + path,
  }
  request({
    url: '/visitLog',
    data: params,
    method: 'post',
  })
}
