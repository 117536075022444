<template>
  <input type="number" ref="numInput" :placeholder="placeholder" v-model.number="inputModel" />
</template>

<script>
// point: 小数位数
// max: 最大长度
let _this = ''
export default {
  props: {
    point: {
      default: 0,
    },
    max: Number,
    placeholder: {
      default: '请输入内容',
    },
    value: {
      default: null,
    },
  },
  data() {
    return {
      keyDownDel: false, // 判断键盘输入值
    }
  },
  computed: {
    inputModel: {
      get: function() {
        // 父组件==>子组件 发消息
        return this.value
      },
      set: function(v) {
        let elval = this.$el.value
        let len = elval.length

        // 解决首位直接输入 '0开头的数字'问题
        if (len === 2 && elval.charAt(0) === '0' && elval.charAt(1) !== '.') {
          this.$el.value = elval.charAt(1)
          this.setParentModeVal(this.$el.value)
          return
        }

        // 解决数字键盘可以输入输入多个小数点问题
        if (Math.abs(this.value) > 0 && elval === '' && v === '') {
          if (_this.keyDownDel) {
            this.$el.value = '' // 正常删除
          } else {
            this.$el.value = this.value // 多次输入小数点时
          }
          this.setParentModeVal(this.$el.value)
          return
        }

        // 解决开始就输入点问题
        if (this.value === '' && elval === '' && v === '') {
          this.$el.value = ''
          this.setParentModeVal('')
          return
        }

        // 解决保留两位小数问题
        if (elval) {
          let pointIndex = elval.indexOf('.')

          if (this.point === 0 && elval.charAt(pointIndex) === '.') {
            this.$el.value = elval.replace('.', '')
            this.setParentModeVal(this.$el.value)
            return
          }
          // if (this.point === 0 && len >= 2 && elval.charAt(pointIndex) === '.') {
          //   this.$el.value = elval.substr(0, pointIndex)
          //   this.setParentModeVal(this.$el.value)
          //   return
          // }
          if (pointIndex > 0 && len - pointIndex > this.point + 1) {
            this.$el.value = elval.substr(0, pointIndex + this.point + 1)
            this.setParentModeVal(this.$el.value)
            return
          }
        }

        // 解决输入最大值问题
        if (this.max > 0 && elval > this.max) {
          this.$el.value = elval.substr(0, len - 1)
          this.setParentModeVal(this.$el.value)
          return
        }

        this.setParentModeVal(elval)
      },
    },
  },
  methods: {
    setParentModeVal(value) {
      this.$emit('input', Number(value))
    },
  },
  mounted() {
    // 判断键盘是否是删除动作
    _this = this
    window.document.onkeydown = function(event) {
      let e = event || window.event || arguments.callee.caller.arguments[0]
      if (e.keyCode === 8 || e.keyCode === 46) {
        _this.keyDownDel = true
      } else {
        _this.keyDownDel = false
      }
    }
  },
}
</script>

<style lang="scss" scoped>
input {
  height: 32px;
  line-height: 32px;
  box-sizing: border-box;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  display: inline-block;
  font-size: inherit;
  outline: 0;
  width: 100%;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 0 7px;
}

input::-webkit-input-placeholder {
  color: #c0c4cc;
}
input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #c0c4cc;
}
input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #c0c4cc;
}
input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #c0c4cc;
}
</style>
