import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ismobile: false,
    basicInfo: '', //基础信息
    maintInfo: null, //维保
    projectMalls: [],
    dashboardHash: '', //路由hash
    leftNav: [], //左侧导航
    menus: [], //菜单
    defaultMenus: null,
    dashboards: [], //所有书签
    allcards: [], //所有卡片
    pageunit: '', //计数单位
    targets: [], //所有指标
    envdata: [], //用户，店铺 属性信息
    errorCounter: new Set(), //异常消息计数器
    dashboardDetail: [], //dashboard详情
    cardlists: [],
    scenesCode: '', //场景code
    projectID: '',
    system: 'bi',
    cancelTokenArr: [], //取消请求的数组,
    festivals: {
      loading: true,
      statutory: [],
      custom: [],
    }, //法定节假日描述
    weathers: {
      loading: true,
      data: [],
    }, //城市天气
    news: null, //公告消息
    behaviorMeta: '', //事件 元数据
    attributeMeta: '', //实体 元数据
    operator: '', //类型
    changePPTPages: [], // PPT改变的页面

    fullPath: '', //当前页面path

    mallId: '',
  },
  actions: {},
  mutations: {
    setWeathers(state, payload) {
      state.weathers = payload
    },
    setFestivals(state, payload) {
      state.festivals = payload
    },
    setProjectID(state, payload) {
      state.projectID = payload
    },
    setSystem(state, payload) {
      state.system = payload
    },
    //设置手机
    setismobile(state, payload) {
      state.ismobile = payload
    },
    // 基础信息
    setbasicInfo(state, payload) {
      state.basicInfo = payload
    },
    //维保
    setmaintInfo(state, payload) {
      state.maintInfo = payload
    },
    setProjectMalls(state, payload) {
      state.projectMalls = payload
    },
    // 路由hash
    setdashboardHash(state, payload) {
      state.dashboardHash = payload
    },
    // nav赋值
    setLeftNav(state, payload) {
      state.leftNav = payload
    },
    // 菜单赋值
    setMenus(state, payload) {
      state.menus = payload
    },
    // 菜单赋值
    setDefaultMenus(state, payload) {
      state.defaultMenus = payload
    },
    // 书签赋值
    setDashboards(state, payload) {
      state.dashboards = payload
    },
    // 卡片赋值
    setAllcards(state, payload) {
      state.allcards = payload
    },
    //计数单位设置
    setPageunit(state, payload) {
      state.pageunit = payload
    },
    // 指标赋值
    setTargets(state, payload) {
      state.targets = payload
    },
    //用户，店铺 属性信息
    setEnvdata(state, payload) {
      state.envdata = payload
    },
    setNews(state, payload) {
      state.news = payload
    },
    //dashboard详情
    setDashboardDetail(state, payload) {
      state.dashboardDetail = payload
    },
    // 卡片集合
    setCardlists(state, payload) {
      state.cardlists = payload
    },
    // 场景内容
    setScenesCode(state, payload) {
      state.scenesCode = payload
    },
    setBehaviorMeta(state, payload) {
      state.behaviorMeta = payload
    },
    setAttributeMeta(state, payload) {
      state.attributeMeta = payload
    },
    setOperators(state, payload) {
      state.operator = payload
    },
    setChangePPTPages(state, payload) {
      state.changePPTPages = payload
    },
    setFullPath(state, payload) {
      state.fullPath = payload
    },

    pushToken: (state, src) => {
      state.cancelTokenArr.push(src.cancelToken)
    },
    clearToken: ({ cancelTokenArr }) => {
      cancelTokenArr.forEach(item => {
        item('路由跳转取消请求')
      })
      cancelTokenArr = []
    },

    setMallId(state, payload) {
      state.mallId = payload
    },
  },
  getters: {
    mallId: state => {
      return state.mallId
    },
  },
})
