let tenantMode = window.MallcooApi.tenantMode
let SiteHost = window.MallcooApi.SiteHost
let baseApi = window.MallcooApi.biapi
let bimApi = window.MallcooApi.bimapi
let baseLogin = window.MallcooApi.loginUrl
let changePwd = window.MallcooApi.accountEditpwd
let mpUrl = window.MallcooApi.mpUrl
let bigmanage = window.MallcooApi.bimUrl
let cookie_domain = window.MallcooApi.domain
let mpmanage = window.MallcooApi.mpmanage
let analysisUrl = window.MallcooApi.analysisUrl
// 是否代理
const analysisProxy = window.MallcooApi.isproxy ? '/v2/analysis' : ''
const dashboardProxy = window.MallcooApi.isproxy ? '/v2/dashboard' : ''
const manageProxy = window.MallcooApi.isproxy ? '/v2' : ''

//测试环境
if (location.href.includes('mp-t')) {
  bimApi = window.MallcooApi.mp_t.bimapi
  baseApi = window.MallcooApi.mp_t.biapi
  cookie_domain = window.MallcooApi.mp_t.domain
  baseLogin = window.MallcooApi.mp_t.loginUrl
  changePwd = window.MallcooApi.mp_t.accountEditpwd
  mpUrl = window.MallcooApi.mp_t.mpUrl
  bigmanage = window.MallcooApi.mp_t.bimUrl
  mpmanage = window.MallcooApi.mp_t.mpmanage
  analysisUrl = window.MallcooApi.mp_t.analysisUrl
}
//预发布环境
if (location.href.includes('mp-p')) {
  bimApi = window.MallcooApi.mp_p.bimapi
  baseApi = window.MallcooApi.mp_p.biapi
  cookie_domain = window.MallcooApi.mp_p.domain
  baseLogin = window.MallcooApi.mp_p.loginUrl
  changePwd = window.MallcooApi.mp_p.accountEditpwd
  mpUrl = window.MallcooApi.mp_p.mpUrl
  bigmanage = window.MallcooApi.mp_p.bimUrl
  mpmanage = window.MallcooApi.mp_p.mpmanage
  analysisUrl = window.MallcooApi.mp_p.analysisUrl
}
//本地
if (process.env.NODE_ENV === 'development') {
  cookie_domain = window.MallcooApi.mp_t.domain
  baseLogin = window.MallcooApi.mp_t.loginUrl
  changePwd = window.MallcooApi.mp_t.accountEditpwd
  bigmanage = window.MallcooApi.mp_t.bimUrl
  SiteHost = process.env.VUE_APP_baseApi_v1
  baseApi = process.env.VUE_APP_baseApi_v2_dashboard
  mpUrl = window.MallcooApi.mp_t.mpUrl
  mpmanage = window.MallcooApi.mp_t.mpmanage
  analysisUrl = window.MallcooApi.mp_t.analysisUrl
  bimApi = process.env.VUE_APP_BimApi
}
export default {
  tenantMode,
  SiteHost,
  baseApi,
  analysisUrl,
  baseLogin,
  changePwd,
  bigmanage,
  cookie_domain,
  bimApi,
  mpUrl,
  mpmanage,
  analysisProxy,
  dashboardProxy,
  manageProxy,
}
