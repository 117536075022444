import Vue from 'vue'
import BiInput from '@/components/Plugins/BiInputs'
Vue.use(BiInput)
Vue.component('BiInput', BiInput)

import { extDatetime, showDatetime } from 'bi_common'
Vue.use(extDatetime)
Vue.use(showDatetime)
Vue.component('extDatetime', extDatetime)
Vue.component('showDatetime', showDatetime)

import bi_echarts from 'mallcoo_bi_echarts'
Vue.use(bi_echarts.vueBiEchatsObj)
import { subscribePlan } from 'mallcoo_bi_subscribe'
Vue.use(subscribePlan)
Vue.component('subscribePlan', subscribePlan)
