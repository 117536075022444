import request from '@/utils/request2'

// 节假日描述
export function getFestival(data) {
  return request({
    url: '/festival/description/summary',
    method: 'get',
    params: data,
  })
}

//筛选条件
export function getFilterOption(data) {
  return request({
    url: '/metadata/filter/condition/option',
    method: 'post',
    data,
  })
}

export function getAvailableLabels(params) {
  return request({
    url: `/label/available`,
    method: 'get',
    params,
  })
}
export function getLabelOptionsForGroup(params) {
  return request({
    url: `/label/options/group`,
    method: 'get',
    params,
  })
}
export function getLabelMirrorOptionsForGroup(params) {
  return request({
    url: `/label/options/mirror/group`,
    method: 'get',
    params,
  })
}
export function getLabelOptionsForFilter(params) {
  return request({
    url: `/label/options/filter`,
    method: 'get',
    params,
  })
}

export function getTagHistoryMirrorDays(labelID) {
  return request({
    url: `/label/mirror/days/success?labelID=${labelID}`,
    method: 'get',
  })
}
//获取勋章
export function getMedals() {
  return request({
    url: '/label/medals',
    method: 'get',
  })
}
