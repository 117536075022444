import store from '@/store'
import numeral from 'numeral'
import { getMenuLoad } from '@/api/apiV2_dashboard'

let commonJs = {
  //图表数值处理 样式配置 计数单位优先级最高，其次全局计数单位配置
  processnumunit: (num, setting, displayType, propfmt) => {
    const styleSet = setting.styleSet
    const propSetprops = setting?.propSet?.props ?? []
    if (styleSet.numberunit && styleSet.numberunit !== 'none') {
      return numUnit(styleSet, propSetprops, num)
    } else {
      return commonJs.propnumformat(store.state.pageunit, num, styleSet.enablePencentage, displayType, propfmt)
    }
  },
  //获取宽度
  getyAxixWidth: v => {
    const w = String(v).replace(/[-._]/g, 'a')
    return w.replace(/[\u0391-\uFFE5]/g, 'aa').length * 6.6 + 10
  },

  //获取文字宽度
  getWordsWidth: v => {
    if (typeof v == 'number') {
      v = v.toFixed(1)
    }
    if (typeof v == 'number' && !String(v).includes('.')) {
      v += '.aa'
    } else {
      if (String(v).includes('.')) {
        v += 'a'
      } else {
        v += '.a'
      }
    }
    const w = String(v).replace(/[-._]/g, 'a')
    return w.replace(/[\u0391-\uFFE5]/g, 'aa').length * 6.6 + 10
  },
  getWordsWidth2: v => {
    const w = String(v).replace(/[-._]/g, 'a')
    return w.replace(/[\u0391-\uFFE5]/g, 'aa').length * 6.6 + 15
  },
  //格式化数字
  formatnum: (pageunit, num, pencentage = false, displayType) => {
    // pageunit chinese 为 万亿单位  international 为K M单位 none为原数据
    if (pencentage) {
      return num + '%'
    } else if (pageunit == 'chinese') {
      if (typeof num == 'number') {
        // 万以内直接返回
        if (Math.abs(num) < 10000) {
          return displayType ? commonJs.formatDisplay2(num, displayType) : num
        }
        //大于8位数是亿
        else if (Math.abs(num) >= 100000000) {
          return parseFloat((num / 100000000).toFixed(1)) + '亿'
        }
        //大于6位数是十万 (以10W分割 10W以下全部显示)
        else if (Math.abs(num) >= 10000) {
          return parseFloat((num / 10000).toFixed(1)) + '万'
        }
      } else {
        return num
      }
    } else if (pageunit == 'international') {
      if (typeof num == 'number') {
        if (Math.abs(num) < 1000) {
          return displayType ? commonJs.formatDisplay2(num, displayType) : num
        } else if (Math.abs(num) >= 1000000) {
          return parseFloat((num / 1000000).toFixed(1)) + 'M'
        } else if (Math.abs(num) >= 1000) {
          return parseFloat((num / 1000).toFixed(1)) + 'K'
        }
      } else {
        return num
      }
    } else {
      if (typeof num == 'number') {
        return displayType ? commonJs.formatDisplay2(num, displayType) : num
      } else {
        return num
      }
    }
  },
  //指标值 格式化
  propnumformat: (pageunit, num, pencentage = false, displayType, fmt) => {
    const isfmt = fmt && (fmt.digit || fmt.thousands || fmt.percent)
    if (pageunit == 'chinese') {
      // pageunit chinese 为 万亿单位  international 为K M单位 none为原数据
      if (typeof num == 'number') {
        if (Math.abs(num) < 10000) {
          num = Number.parseFloat(num)
          return displayType ? commonJs.formatDisplay2(num, displayType, fmt) : isfmt ? commonJs.propformat(num, fmt) : num
        } else if (Math.abs(num) >= 100000000) {
          const fmtendnum = parseFloat(num / 100000000)
          const endnum = parseFloat((num / 100000000).toFixed(0))
          return isfmt ? commonJs.propformat(fmtendnum, fmt) + '亿' : endnum + '亿'
        } else if (Math.abs(num) >= 10000) {
          const fmtendnum = parseFloat(num / 10000)
          const endnum = parseFloat((num / 10000).toFixed(0))
          return isfmt ? commonJs.propformat(fmtendnum, fmt) + '万' : endnum + '万'
        }
      } else {
        return num
      }
    } else if (pageunit == 'international') {
      if (typeof num == 'number') {
        if (Math.abs(num) < 1000) {
          num = Number.parseFloat(num)
          return displayType ? commonJs.formatDisplay2(num, displayType, fmt) : isfmt ? commonJs.propformat(num, fmt) : num
        } else if (Math.abs(num) >= 1000000) {
          const fmtendnum = parseFloat(num / 1000000)
          const endnum = parseFloat((num / 1000000).toFixed(0))
          return isfmt ? commonJs.propformat(fmtendnum, fmt) + 'M' : endnum + 'M'
        } else if (Math.abs(num) >= 1000) {
          const fmtendnum = parseFloat(num / 1000)
          const endnum = parseFloat((num / 1000).toFixed(0))
          return isfmt ? commonJs.propformat(fmtendnum, fmt) + 'K' : endnum + 'K'
        }
      } else {
        return num
      }
    } else {
      if (typeof num == 'number') {
        return displayType ? commonJs.formatDisplay2(num, displayType, fmt) : isfmt ? commonJs.propformat(num, fmt) : num
      } else {
        return num
      }
    }
  },
  //图表坐标轴 样式配置 计数单位优先级最高，其次全局计数单位配置
  processAxisunit: (max, num, setting, pencentage = false) => {
    const styleSet = setting.styleSet
    const fmt = styleSet.yAxisformat
    if (typeof num !== 'number') return num
    if (styleSet.yAxisNumUnit && styleSet.yAxisNumUnit != 'none' && !pencentage) {
      return AxisUnit(styleSet, num, fmt)
    } else {
      return commonJs.formatAxis(store.state.pageunit, max, num, pencentage, fmt)
    }
  },
  //格式化坐标轴数字
  formatAxis: (pageunit, max, num, pencentage = false, fmt) => {
    const isfmt = fmt && (fmt.digit || fmt.thousands || fmt.percent)
    // pageunit chinese 为 万亿单位  international 为K M单位 none为原数据
    if (pencentage) {
      return num + '%'
    } else if (pageunit == 'chinese') {
      if (typeof num == 'number') {
        if (Math.abs(max) < 10000) {
          return isfmt ? commonJs.propformat(num, fmt) : num.toFixed(1)
        } else if (Math.abs(max) >= 100000000) {
          const fmtendnum = parseFloat(num / 100000000)
          const endnum = parseFloat((num / 100000000).toFixed(1))
          return isfmt ? commonJs.propformat(fmtendnum, fmt) + '亿' : endnum + '亿'
        } else if (Math.abs(max) >= 10000) {
          const fmtendnum = parseFloat(num / 10000)
          const endnum = parseFloat((num / 10000).toFixed(1))
          return isfmt ? commonJs.propformat(fmtendnum, fmt) + '万' : endnum + '万'
        }
      } else {
        return num
      }
    } else if (pageunit == 'international') {
      if (typeof num == 'number') {
        if (Math.abs(max) < 1000) {
          return isfmt ? commonJs.propformat(num, fmt) : num.toFixed(1)
        } else if (Math.abs(max) >= 1000000) {
          const fmtendnum = parseFloat(num / 1000000)
          const endnum = parseFloat((num / 1000000).toFixed(1))
          return isfmt ? commonJs.propformat(fmtendnum, fmt) + 'M' : endnum + 'M'
        } else if (Math.abs(max) >= 1000) {
          const fmtendnum = parseFloat(num / 1000)
          const endnum = parseFloat((num / 1000).toFixed(1))
          return isfmt ? commonJs.propformat(fmtendnum, fmt) + 'K' : endnum + 'K'
        }
      } else {
        return num
      }
    } else {
      if (typeof num == 'number') {
        return isfmt ? commonJs.propformat(num, fmt) : num.toFixed(1)
      } else {
        return num
      }
    }
  },

  //对分组值格式化
  formatDimValue: (targets, query, dimValues, options) => {
    let ret = dimValues
    if (dimValues.length > 0) {
      let skipFirst = options && options.skipFirst ? options.skipFirst : false
      let onlyFirst = options && options.onlyFirst ? options.onlyFirst : false
      var dimensions = getDimensions(targets, query)
      dimensions = skipFirst ? dimensions.Skip(1) : dimensions

      if (
        dimensions.any(d => {
          return d.format
        })
      ) {
        if (skipFirst) {
          dimValues = self.filteredResult.current.otherAllDimValues
        }

        ret = dimValues.map(x => {
          var text = null
          if (onlyFirst) {
            text = dimensions[0].format ? filtertags(x, dimensions[0].format) : x
          } else {
            var row = self.currentServerResultDic[x]
            text = dimensions
              .map(function(t) {
                var value = row[t.code]
                // return t.format ? $filter('format')(value, t.format) : value;
              })
              .join(',')
          }
          return text
        })
      }
    }
    return ret
  },
  //千分位分割
  kformater: number => {
    let numArr = number
    if (number.includes('.')) {
      numArr = number.split('.')
      let numLeft = String(numArr[0]).replace(/\d{1,3}(?=(\d{3})+(\.|$))/g, '$&,')
      return numLeft + '.' + numArr[1]
    } else {
      let numLeft = String(numArr).replace(/\d{1,3}(?=(\d{3})+(\.|$))/g, '$&,')
      return numLeft
    }
  },
  //图表悬浮框
  getTooltipHtml: (params, data, hasContrast, weatherData) => {
    let myparam = []
    params.forEach((x, i) => {
      let item = data[i]
      if (item) {
        x.code = item.code
        x.measure = item.measure
        x.displayName = item.displayName
        x.displayType = item.displayType
        x.contrastType = item.contrastType ? item.contrastType : 0
        myparam.push(x)
      }
    })

    let pA = [
      ...new Set(
        myparam.map(x => {
          return x.contrastType
        })
      ),
    ]
    let constrastGroups = []
    pA.map((tag, i) => {
      let list = myparam.filter(x => {
        return x['contrastType'] == tag
      })
      constrastGroups[i] = list
      constrastGroups[i]['key'] = tag
    })

    let names = params[0].name.split('/')
    let htmls = []
    constrastGroups.forEach((c, i) => {
      let contrastKey = c.key
      let titleHtml = ''
      if (contrastKey == 0) {
        titleHtml = names[0]
      } else if (contrastKey == 1) {
        titleHtml = names[0] + '-对比'
      } else if (contrastKey == 2) {
        titleHtml = names[0] + '-同比'
      } else if (contrastKey == 3) {
        titleHtml = names[0] + '-环比'
      }

      let html = "<h3 style='font-weight:normal;padding:4px 5px;'>" + titleHtml + '</h3>'

      let cA = [
        ...new Set(
          c.map(x => {
            return x.code
          })
        ),
      ]
      let measureGroups = []
      cA.map((tag, i) => {
        let list = c.filter(x => {
          return x['code'] == tag
        })
        measureGroups[i] = list
        measureGroups[i]['key'] = tag
      })

      measureGroups.forEach((list, midx) => {
        if (list.length == 1) {
          let span = "<span style='width:6px;height:6px;border-radius:4px;margin:8px 5px 0;float:left;background:" + list[0].color + ";'></span>"
          html += "<div style='padding-left:5px;text-align:left;'>" + span + list[0].measure + '&nbsp&nbsp' + list[0].value + '&nbsp&nbsp</div>'
        } else {
          if (list[0].measure && list[0].measure != list[0].displayName) {
            html += "<div style='padding-left:5px;text-align:left;'>" + list[0].measure + '&nbsp&nbsp</div>'
          }
          list.forEach((m, k) => {
            let value = m.value
            let span = "<span style='width:6px;height:6px;border-radius:4px;margin:8px 5px 0;float:left;background:" + list[k].color + ";'></span>"

            html += "<div style='padding:2px 0 3px;text-align:left;'>" + span + data[list[k].seriesIndex].displayName + ' : ' + value + '&nbsp&nbsp' + '</div>'
          })
        }
      })
      htmls.push(html)
    })
    let tooltipHtml = "<div style='max-height:300px;overflow-y:auto;padding:5px;border-radius:4px;'>" + htmls.join('') + '</div>'
    return tooltipHtml
  },
  //桑基图浮框
  getTooltipSankey: (params, data, hasContrast, weatherData) => {
    let myparam = params.map(x => {
      let item = data[x.seriesIndex]
      x.code = item.code
      x.measure = item.measure
      x.contrastType = item.contrastType ? item.contrastType : 0

      return x
    })

    let mA = [
      ...new Set(
        myparam.map(x => {
          return x.contrastType
        })
      ),
    ]
    let constrastGroups = []
    mA.map((tag, i) => {
      let list = myparam.filter(x => {
        return x['contrastType'] == tag
      })
      constrastGroups[i] = list
      constrastGroups[i]['key'] = tag
    })

    let names = params[0].name.split('/')
    let htmls = []

    constrastGroups.forEach((c, i) => {
      let contrastKey = c.key
      let titleHtml = ''
      if (contrastKey == 0) {
        titleHtml = names[0]
      } else if (contrastKey == 1) {
        titleHtml = names[0] + '-对比'
      } else if (contrastKey == 2) {
        titleHtml = names[0] + '-同比'
      } else if (contrastKey == 3) {
        titleHtml = names[0] + '-环比'
      }

      let html = "<h3 style='font-weight:normal;padding:4px 5px;'>" + titleHtml + '</h3>'

      let cA = [
        ...new Set(
          c.map(x => {
            return x.code
          })
        ),
      ]
      let measureGroups = []
      cA.map((tag, i) => {
        let list = c.filter(x => {
          return x['code'] == tag
        })
        measureGroups[i] = list
        measureGroups[i]['key'] = tag
      })

      measureGroups.forEach((list, midx) => {
        if (list.length == 1) {
          let span = "<span style='width:6px;height:6px;border-radius:4px;margin:8px 5px 0;float:left;background:" + list[0].color + ";'></span>"
          html += "<div style='padding-left:5px;text-align:left;'>" + span + list[0].measure + '&nbsp&nbsp' + list[0].value + '&nbsp&nbsp</div>'
        } else {
          if (list[0].measure) {
            html += "<div style='padding-left:5px;text-align:left;'>" + list[0].measure + '&nbsp&nbsp</div>'
          }
          list.forEach((m, k) => {
            let value = m.value
            let span = "<span style='width:6px;height:6px;border-radius:4px;margin:8px 5px 0;float:left;background:" + list[k].color + ";'></span>"

            // if(params[i].seriesType == 'line'){
            //   span= "<span style='width:10px;height:0px;margin:8px 5px 0;float:left;border-bottom:1px dashed " + list[k].color + ";'></span>"
            // }else {
            //   span = "<span style='width:10px;height:5px;margin:7px 5px;float:left;background-color: " + list[k].color + ";'></span>"
            // }
            html += "<div style='padding:2px 0 3px;text-align:left;'>" + span + data[list[k].seriesIndex].displayName + ' : ' + value + '&nbsp&nbsp' + '</div>'
          })
        }
      })
      htmls.push(html)
    })
    let tooltipHtml = "<div style='max-height:300px;overflow-y:auto;padding:5px;border-radius:4px;'>" + htmls.join('') + '</div>'
    return tooltipHtml
  },

  //图表悬浮框2.0
  getTooltipHtml2: (params, data, hasContrast, weatherData) => {},

  //链接转换Base64
  getxBase64Url: (bookmark, dashboard, projectID) => {
    let definition = JSON.parse(bookmark.definition)
    let p = definition.data ? definition.data : definition
    //路径分析 timeRange 需要为数组形式
    if (definition.code == 'path') {
      let pathtime = []
      if (Array.isArray(p.timeRange)) {
      } else {
        pathtime.push(p.timeRange)
        p.timeRange = pathtime
        bookmark.definition = JSON.stringify(definition)
      }

      // url 里的 timeRange 需要为数组形式
    }

    let url = ''
    if (!bookmark.url) {
      return
    }
    var index = bookmark.url.indexOf('#')
    if (bookmark.segmentID) {
      var uleft = bookmark.url.substring(0, index)
      var uright = bookmark.url.substring(index)
      var a = uleft.lastIndexOf('/')
      var projectIDParam = uleft.substring(a + 1, uleft.length)
      var regnum = /^[0-9]+$/
      //旧链接替换项目ID 新链接替换占位符
      if (regnum.test(projectIDParam)) {
        uleft = uleft.substring(0, a + 1) + projectID
      } else {
        uleft = uleft.replace('{projectID}', projectID)
      }
      // bookmark.url = uleft + uright;

      uright = uleft.includes('?') ? uright.replace('#', '&useSence=1#') : uright.replace('#', '?useSence=1#')
      url = uleft + uright
    } else {
      var urlLeftPart = bookmark.url.substring(0, index + 1)
      var urlRightPart = bookmark.url.substring(index + 1)

      var a = urlLeftPart.lastIndexOf('/')
      var b = urlLeftPart.indexOf('?')
      var projectIDParam = urlLeftPart.substring(a + 1, b)
      var regnum = /^[0-9]+$/
      //旧链接替换项目ID 新链接替换占位符
      if (regnum.test(projectIDParam)) {
        urlLeftPart = urlLeftPart.substring(0, a + 1) + projectID + urlLeftPart.substring(b)
      } else {
        urlLeftPart = urlLeftPart.replace('{projectID}', projectID)
      }

      if (bookmark.color) {
        var data = JSON.parse(urlRightPart)
        data.color = bookmark.color
        urlRightPart = JSON.stringify(data)
      }

      // 路径分析的url 的timeRange 要为数组
      if (definition.code == 'path') {
        let newurlRightPart = JSON.parse(urlRightPart)
        let pathtime = []
        if (Array.isArray(newurlRightPart.timeRange)) {
        } else {
          pathtime.push(newurlRightPart.timeRange)
          newurlRightPart.timeRange = pathtime
          urlRightPart = JSON.stringify(newurlRightPart)
        }
      }

      var isBusiness = dashboard && dashboard.type == 'Business'
      if (isBusiness) {
        url = null
      } else {
        urlLeftPart = urlLeftPart.includes('?') ? urlLeftPart.replace('#', '&useSence=1#') : urlLeftPart.replace('#', '?useSence=1#')
        url = urlLeftPart + encodeURIComponent(urlRightPart)
      }
    }
    return url
  },
  // 计算比较日期
  getContrastTimeV1: (_this, req, emit, isRelative, iscontrast) => {
    //如果修改的是对比时间，获取当前时间参数并修改对比时间
    //否则获取时间参数 如果有对比 第二个时间不修改否则计算对比时间
    if (iscontrast) {
      let newTime = req.injectedArguments && req.injectedArguments.timeRange ? req.injectedArguments.timeRange : req.timeRange
      let range = commonJs.initToV1Time(_this, emit.v2time)
      newTime[1] = range[0]
      return newTime
    } else {
      let timeRange = emit && emit.v2time ? emit.v2time : req.timeRange
      timeRange = commonJs.initToV1Time(_this, timeRange)
      let times = JSON.parse(JSON.stringify(timeRange))
      let newTime = []
      newTime.push(times[0])

      let dates1 = JSON.parse(JSON.stringify(timeRange[0].dates))
      newTime[0].model = timeRange[0].model
      newTime[0].dates = dates1
      const diff_days = _this.moment(dates1[1]).diff(_this.moment(dates1[0]), 'days')
      let dates2 = []
      let dates3 = []

      if (req.contrastType == 'nocontrast') {
      } else if (req.contrastType == 'contrast' || req.contrastType == 'circle') {
        const date1 = _this
          .moment(dates1[0])
          .subtract(diff_days + 1, 'days')
          .format('YYYY-MM-DD')
        const date2 = _this
          .moment(dates1[0])
          .subtract(1, 'days')
          .format('YYYY-MM-DD')
        dates2 = [date1, date2]
        const time2 = {
          dates: dates2,
          model: 'Absolute',
          unit: 'day',
          value: 1,
        }
        newTime.push(time2)
      } else if (req.contrastType == 'last') {
        const date1 = _this
          .moment(dates1[1])
          .subtract('12', 'months')
          .format('YYYY-MM-DD')
        const date2 = _this
          .moment(date1)
          .subtract(diff_days, 'days')
          .format('YYYY-MM-DD')
        dates2 = [date2, date1]
        const time2 = {
          dates: dates2,
          model: 'Absolute',
          unit: 'day',
          value: 1,
        }
        newTime.push(time2)
      } else if (req.contrastType == 'lastandcircle') {
        const date1 = _this
          .moment(dates1[1])
          .subtract('12', 'months')
          .format('YYYY-MM-DD')
        const date2 = _this
          .moment(date1)
          .subtract(diff_days, 'days')
          .format('YYYY-MM-DD')
        dates2 = [date2, date1]
        const time2 = {
          dates: dates2,
          model: 'Absolute',
          unit: 'day',
          value: 1,
        }
        newTime.push(time2)

        const c_date1 = _this
          .moment(dates1[0])
          .subtract(diff_days + 1, 'days')
          .format('YYYY-MM-DD')
        const c_date2 = _this
          .moment(dates1[0])
          .subtract(1, 'days')
          .format('YYYY-MM-DD')
        dates3 = [c_date1, c_date2]

        const time3 = {
          dates: dates3,
          model: 'Absolute',
          unit: 'day',
          value: 1,
        }
        newTime.push(time3)
      }
      return newTime
    }
  },
  getContrastTimeV2: (_this, req, emit, isRelative, iscontrast) => {
    //如果修改的是对比时间，获取当前时间参数并修改对比时间
    //否则获取时间参数 如果有对比 第二个时间不修改否则计算对比时间
    if (iscontrast) {
      let newTime =
        req.injectedArguments && req.injectedArguments.timeRange ? req.injectedArguments.timeRange : req.timeRange ? req.timeRange : req.parameter.timeRanges
      newTime[1] = emit.v2time[0]
      return newTime
    } else {
      let timeRange = emit && emit.v2time ? emit.v2time : req.timeRange ? req.timeRange : req.parameter.timeRanges
      let times = JSON.parse(JSON.stringify(timeRange))
      let newTime = []
      newTime.push(times[0])
      if (req.contrastType == 'contrast') {
        newTime.push(times[1])
      }
      return newTime
    }
  },
  //根据指标和分组方式 获取x轴，图例，和数据
  getxAxisdata: (selectedGroups, columns) => {},
  //根据分组的类型看是否转化
  getname: (group, key) => {
    let name = ''
    let names = key.split(',')
    group.forEach((g, i) => {
      if (g.type == 'bool') {
        names[i] = g.text + ':' + (names[i] == '-' ? '-' : names[i] == 0 ? '否' : '是')
      }
    })
    names.forEach(n => {
      name += n + ','
    })
    name = name.substr(0, name.length - 1)
    return name
  },

  initToV1Time(_this, timeRange) {
    let time = []
    let firsttime = timeRange[0]
    let mode = timeRange[0].mode
    //v2 time
    if (mode) {
      if (mode == 'Absolute') {
        let list = {
          dates: [firsttime.start, firsttime.end],
          isCustomDatePeriod: false,
          model: firsttime.mode,
          unit: 'Week',
          value: '0',
        }
        time.push(list)
      }
      if (mode == 'Relative') {
        let dates = commonJs.getshowdata(_this, firsttime)
        let defines = firsttime.relative.defines
        let list = {}
        if (defines.length == 2) {
          list = {
            dates: [dates[0], dates[1]],
            isCustomDatePeriod: true,
            model: firsttime.mode,
            unit: firsttime.relative.unit,
            value: 0,
          }
        } else {
          list = {
            dates: [dates[0], dates[1]],
            isCustomDatePeriod: false,
            model: firsttime.mode,
            unit: firsttime.relative.unit,
            value: -firsttime.relative.defines[0].unitValue,
          }
        }
        time.push(list)
      }
      if (mode == 'StatutoryFestival') {
        let list = {
          dates: [firsttime.start, firsttime.end],
          isCustomDatePeriod: false,
          model: 'Festival',
          unit: 'Year',
          value: 0,
        }
        time.push(list)
      }
      if (mode == 'CustomFestival') {
        let list = {
          dates: [firsttime.start, firsttime.end],
          isCustomDatePeriod: false,
          model: 'CustomFestival',
          unit: 'Year',
          value: 0,
        }
        time.push(list)
      }
    } else {
      time.push(firsttime)
    }
    return time
  },

  getshowdata(_this, time) {
    let relative = time.relative
    let defines = time.relative.defines
    let date = []
    // 是否过去自定义
    if (defines[0].value && (!relative.mode || (relative.mode !== 'FromPastDayToLast' && relative.mode !== 'FromPastToPast'))) {
      if (time.relative.unit == 'Week') {
        date[0] = _this
          .moment()
          .weekday(-8 + defines[0].value)
          .format('YYYY-MM-DD')
        date[1] = _this
          .moment()
          .weekday(defines[1].value - 1)
          .format('YYYY-MM-DD')
      }
      if (time.relative.unit == 'Month') {
        const lastMonthDays = _this
          .moment()
          .subtract(1, 'months')
          .daysInMonth()
        const curMonthDays = _this.moment().daysInMonth()
        let day0 = defines[0].value > 9 ? defines[0].value : defines[0].value > 0 ? '0' + defines[0].value : lastMonthDays + defines[0].value + 1
        let day1 = defines[1].value > 9 ? defines[1].value : defines[1].value > 0 ? '0' + defines[1].value : curMonthDays + defines[1].value + 1
        date[0] =
          _this
            .moment()
            .subtract(1, 'months')
            .format('YYYY-MM') +
          '-' +
          day0
        date[1] = _this.moment().format('YYYY-MM') + '-' + day1
      }
      if (time.relative.unit == 'Year') {
        date[0] =
          _this
            .moment()
            .subtract(1, 'years')
            .format('YYYY') +
          '-' +
          defines[0].value
        date[1] = _this.moment().format('YYYY') + '-' + defines[1].value
      }
    } else {
      if (relative.mode && relative.mode === 'FromPastDayToLast') {
        const unitValue = -Number(defines[1].unitValue)
        date[0] = defines[0].value
        if (relative.unit == 'Day') {
          date[1] = _this
            .moment()
            .subtract(unitValue, 'days')
            .format('YYYY-MM-DD')
        }
        if (relative.unit == 'Week') {
          date[1] = _this
            .moment()
            .weekday(-1)
            .format('YYYY-MM-DD')
        }
        if (relative.unit == 'Month') {
          date[1] = _this
            .moment(_this.moment().format('YYYY-MM') + '-01')
            .subtract(1, 'days')
            .format('YYYY-MM-DD')
        }
        if (relative.unit == 'Year') {
          date[1] = _this
            .moment(_this.moment().format('YYYY') + '-01-01')
            .subtract(1, 'days')
            .format('YYYY-MM-DD')
        }
      } else if (relative.mode && relative.mode === 'FromPastToPast') {
        const unitValue0 = -Number(defines[0].unitValue)
        const unitValue1 = -Number(defines[1].unitValue)
        if (relative.unit == 'Day') {
          date[0] = _this
            .moment()
            .subtract(unitValue0, 'days')
            .format('YYYY-MM-DD')
          date[1] = _this
            .moment()
            .subtract(unitValue1, 'days')
            .format('YYYY-MM-DD')
        }
        if (relative.unit == 'Week') {
          date[0] = _this
            .moment()
            .add(-unitValue0, 'week')
            .startOf('week')
            .format('YYYY-MM-DD')
          date[1] = _this
            .moment()
            .add(-unitValue1, 'week')
            .endOf('week')
            .format('YYYY-MM-DD')
        }
        if (relative.unit == 'Month') {
          date[0] =
            _this
              .moment()
              .subtract(unitValue0, 'months')
              .format('YYYY-MM') + '-01'
          date[1] = _this
            .moment(
              _this
                .moment()
                .subtract(unitValue1 - 1, 'months')
                .format('YYYY-MM') + '-01'
            )
            .subtract(1, 'days')
            .format('YYYY-MM-DD')
        }
        if (relative.unit == 'Year') {
          date[0] =
            _this
              .moment()
              .subtract(unitValue0, 'years')
              .format('YYYY') + '-01-01'
          date[1] = _this
            .moment(
              _this
                .moment()
                .subtract(unitValue1 - 1, 'years')
                .format('YYYY') + '-01-01'
            )
            .subtract(1, 'days')
            .format('YYYY-MM-DD')
        }
      } else {
        let unit = time.relative.unit
        let unitValue = defines[0].unitValue != 0 ? Math.abs(defines[0].unitValue) : 0
        if (unit == 'All') {
          date[0] = _this.moment(_this.basicInfo.project.onlineDate).format('YYYY-MM-DD')
          date[1] = _this.moment().format('YYYY-MM-DD')
        } else if (unit == 'Day' && unitValue == 0) {
          date[0] = _this
            .moment()
            .subtract(unitValue, 'days')
            .format('YYYY-MM-DD')
          date[1] = _this
            .moment()
            .subtract(unitValue, 'days')
            .format('YYYY-MM-DD')
        } else if (unit == 'Day' && unitValue != 0) {
          date[0] = _this
            .moment()
            .subtract(unitValue, 'days')
            .format('YYYY-MM-DD')
          date[1] = _this
            .moment()
            .subtract(1, 'days')
            .format('YYYY-MM-DD')
        } else if (unit == 'Week' && unitValue == 0) {
          date[0] = _this
            .moment()
            .weekday(0)
            .format('YYYY-MM-DD')
          date[1] = _this.moment().format('YYYY-MM-DD')
        } else if (unit == 'Week' && unitValue == 1) {
          date[0] = _this
            .moment()
            .weekday(-7)
            .format('YYYY-MM-DD')
          date[1] = _this
            .moment()
            .weekday(-1)
            .format('YYYY-MM-DD')
        } else if (unit == 'Week' && unitValue > 1) {
          date[0] = _this
            .moment()
            .add(-unitValue, 'week')
            .startOf('week')
            .format('YYYY-MM-DD')
          date[1] = _this
            .moment()
            .weekday(-1)
            .format('YYYY-MM-DD')
        } else if (unit == 'Month' && unitValue == 0) {
          date[0] = _this.moment().format('YYYY-MM') + '-01'
          date[1] = _this.moment().format('YYYY-MM-DD')
        } else if (unit == 'Month' && unitValue == 1) {
          date[0] =
            _this
              .moment()
              .subtract(1, 'months')
              .format('YYYY-MM') + '-01'
          date[1] = _this
            .moment(_this.moment().format('YYYY-MM') + '-01')
            .subtract(1, 'days')
            .format('YYYY-MM-DD')
        } else if (unit == 'Month' && unitValue > 1) {
          date[0] =
            _this
              .moment()
              .subtract(unitValue, 'months')
              .format('YYYY-MM') + '-01'
          date[1] = _this
            .moment(_this.moment().format('YYYY-MM') + '-01')
            .subtract(1, 'days')
            .format('YYYY-MM-DD')
        } else if (unit == 'Year' && unitValue == 0) {
          date[0] = _this.moment().format('YYYY') + '-01-01'
          date[1] = _this.moment().format('YYYY-MM-DD')
        } else if (unit == 'Year' && unitValue == 1) {
          date[0] =
            _this
              .moment()
              .subtract(1, 'years')
              .format('YYYY') + '-01-01'
          date[1] = _this
            .moment(_this.moment().format('YYYY') + '-01-01')
            .subtract(1, 'days')
            .format('YYYY-MM-DD')
        } else if (unit == 'Year' && unitValue > 1) {
          date[0] =
            _this
              .moment()
              .subtract(unitValue, 'years')
              .format('YYYY') + '-01-01'
          date[1] = _this
            .moment(_this.moment().format('YYYY') + '-01-01')
            .subtract(1, 'days')
            .format('YYYY-MM-DD')
        }
      }
    }
    return [date[0], date[1]]
  },
  formatDisplay: (value, displayType) => {
    if (!displayType) return value

    let parsed = numeral(value)
    if (parsed.value() == null) return value
    switch (displayType) {
      case 'INTEGER':
        return Number(parsed.format('0'))
      case 'TWO_DECIMAL':
        return parseFloat(parsed.format('0.00'))
      case 'TWO_DECIMAL_STRICT':
        return Number(parsed.format('0.00'))
      case 'PERCENTAGE':
        return parsed.format('0.00%')
      case 'PERCENTAGE_DIRECT':
        return parsed.format('0.00') + '%'
      case 'PERCENTAGE_INTERGER':
        return parsed.format('0') + '%'
      default:
        return parseFloat(parsed.format('0.00'))
    }
  },

  formatDisplay2: (value, displayType, formatobj) => {
    // 开启占比的值拆分一下
    let val = 0,
      per = ''
    if (String(value).includes('%)') || String(value).includes('(-)')) {
      const splitval = value.split('(')
      val = Number(splitval[0])
      per = '(' + splitval[1]
    } else {
      val = value
    }

    if (typeof val != 'number') return val
    if (formatobj && (formatobj.digit != null || formatobj.thousands || formatobj.percent)) return commonJs.propformat(val, formatobj, per)

    let parsed = numeral(val)
    if (parsed.value() == null) return val
    switch (displayType) {
      case 'INTEGER':
        return per ? Number(parsed.format('0')) + per : Number(parsed.format('0'))
      case 'TWO_DECIMAL':
        return per ? parseFloat(parsed.format('0.00')) + per : parseFloat(parsed.format('0.00'))
      case 'TWO_DECIMAL_STRICT':
        return per ? parseFloat(parsed.format('0.00')).toFixed(2) + per : parseFloat(parsed.format('0.00')).toFixed(2)
      case 'PERCENTAGE':
        parsed = numeral(val * 100)
        return per ? parsed.format('0.00%') + per : parsed.format('0.00%')
      case 'PERCENTAGE_DIRECT':
        parsed = numeral(val * 100)
        return per ? parsed.format('0.00') + '%' + per : parsed.format('0.00') + '%'
      case 'PERCENTAGE_INTERGER':
        parsed = numeral(val * 100)
        return per ? parsed.format('0') + '%' + per : parsed.format('0') + '%'
      default:
        return per ? parseFloat(parsed.format('0.00')) + per : parseFloat(parsed.format('0.00'))
    }
  },
  propformat(value, formatobj, per) {
    // 百分比 千分位 小数
    let v = 0
    let parsed = numeral(value)
    let fmt = ''

    if (formatobj.digit !== null && formatobj.digit !== undefined) {
      if (formatobj.thousands) {
        fmt = '0,0'
      } else {
        fmt = '0'
      }
      let zarr = ''
      if (formatobj.digit == 0) {
        zarr = ''
      } else {
        let s = Array.apply(null, Array(formatobj.digit)).map(x => {
          return 0
        })
        zarr = String(s).replace(/[,]/g, '')
      }
      fmt = `${fmt + '.' + zarr}`
    } else {
      if (formatobj.thousands) {
        fmt = '0,0'
      } else {
        fmt = ''
      }
      if (String(value).includes('.')) {
        fmt = fmt ? `${fmt + '.00'}` : '0.00'
      }
    }
    if (formatobj.percent) {
      fmt += '%'
    }
    if (fmt) {
      numeral.defaultFormat(fmt)
      if ((fmt.includes('.') && Math.abs(value) < 1 && String(value).includes(fmt)) || parsed.format() == 'NaN') {
        v = fmt
      } else {
        v = parsed.format()
      }
    } else {
      v = value
    }
    if (value.toString().includes('e-')) {
      v = '0.0'
    }
    if (value.toString().includes('e+')) {
      v = value
    }
    return per ? v + per : v
  },
  getoffset: (i, arr) => {
    if (i > 1) {
      const new_max = addset(i - 2, arr, arr[i - 2])
      return new_max
    } else {
      return 0
    }
    function addset(idx, arr, max) {
      if (idx % 2 == 0) {
        if (idx == 0) {
          return max + 15
        } else {
          max += arr[idx - 2] + 15
          return addset(idx - 2, arr, max)
        }
      } else {
        if (idx == 1) {
          return max + 15
        } else {
          max += arr[idx - 2] + 15
          return addset(idx - 2, arr, max)
        }
      }
    }
  },

  // 销控判断
  async _salejudge(route) {
    let defaultMenus = store.state.defaultMenus
    const code = route.meta.code
    if (!defaultMenus) {
      let meta = await getMenuLoad()
      if (meta && meta.data) {
        defaultMenus = JSON.parse(JSON.stringify(meta.data))
        // 数据面板 获取销控 + 权限都满足的菜单
        defaultMenus.defaultMenus = defaultMenus.defaultMenus.filter(x => x.permissionValidate && x.maintValidate.m == 1)
        const curmenu = meta.data.defaultMenus.find(x => x.code === code)
        // 如果菜单为空 跳到当前菜单销控报错
        if (defaultMenus.defaultMenus.length == 0) {
          if (curmenu && curmenu.maintValidate.m >= 20000) {
            MpGoHandle.init(curmenu.maintValidate)
          }
          return
        }
        store.commit('setDefaultMenus', defaultMenus)
      }
    }
  },
  //权限点控制
  _point(point) {
    let userInfo = store.state.basicInfo.authInfo
    if (userInfo && userInfo.isSuperAdmin) {
      return true
    } else {
      if (userInfo && userInfo.points && userInfo.points.includes(point)) {
        return true
      } else {
        return false
      }
    }
    // return true
  },

  // 临时方案 将卡片定义样式的首字母大写改为小写
  caseToggle(data) {
    let newdata = data.map(x => {
      let newdef = x.definition
      newdef = newdef.replace(/"XAxis(\w+)/, '"xAxis$1')
      newdef = newdef.replace(/"YAxis(\w+)/, '"yAxis$1')
      x.definition = newdef
      return x
    })
    return newdata
  },
  // 兼容历史定义
  compatibleHistory(settings) {
    // table 分组占比兼容
    let { styleSet } = settings
    if (settings && styleSet) {
      if (settings.type === 'table' && styleSet.enableOccupation) {
        //   debugger
        styleSet.compareMethod = styleSet.compareMethod === undefined ? true : styleSet.compareMethod
        styleSet.isOccupationMerge = !!styleSet.isOccupationMerge
      }
      settings.styleSet = styleSet
    }
    return settings
  },
}

//获取选中分组所对应的列
function getDimensions(targets, query) {
  let dimensions = query.selectedGroups.map(function(x, i) {
    let codes = x.code.split('|')
    let code = (x.code == 'eventTime' ? 'day' : codes[0]) + '_dim'
    code = code.toLowerCase()
    let text = codes.length > 1 ? targets[codes[1]] + '的' + x.text : x.text
    let format =
      x.category == 3
        ? {
            type: 'date',
            template: x.value,
          }
        : x.type == 'bool'
        ? {
            type: 'bool',
            template: [text, '：{0}'].join(''),
          }
        : ['int', 'double', 'bigint'].indexOf(x.type) >= 0 && x.category == 1
        ? 'number'
        : null
    format = x.type == 'tag' ? 'tag' : format
    return {
      code: code,
      text: text,
      format: format,
    }
  })
  return dimensions
}
//格式化指标 分组
function filtertags(input, param) {
  let type = param && param.type ? param.type : param
  let ret = type == 'percentage' && input == null ? '-' : input

  if (input != null && input != undefined && input != NaN && param) {
    let boolFormats = {
      0: '否',
      1: '是',
    }
    switch (type) {
      case 'number':
        var number = typeof input == 'string' ? parseFloat(input) : input
        if (isNaN(number)) {
          ret = input
          break
        }
        var isInteger = number % 1 == 0
        number = isInteger ? number : parseFloat(number.toFixed(2))
        isInteger = number % 1 == 0
        // ret = $filter('number')(number)
        ret = number
        if (!isInteger && ret.indexOf('.') + 3 != ret.length) {
          ret += '0'
        }
        break
      case 'date':
        // ret = input.length < 10 ? input : $moment.format(input, param.template)
        ret = input
        break
      case 'bool':
        let boo = [param.template, boolFormats[input] ? boolFormats[input] : input]
        ret = formatString(boo)
        break
      case 'tag':
        ret = input && input.indexOf('__') == 0 ? input.substring(2, input.length - 2) : input
        break
      case 'percentage':
      case 'percent':
        ret = input
        var isNumber = typeof input == 'number'
        if (isNumber) {
          ret = (type == 'percent' ? input : input * 100).toFixed(2) + '%'
        }
        break
    }
  }
  return ret
}

function formatString(item) {
  if (item.length == 0) {
    return null
  }
  var str = item[0]
  for (var i = 1; i < item.length; i++) {
    var re = new RegExp('\\{' + (i - 1) + '\\}', 'gm')
    str = str.replace(re, item[i])
  }
  return str
}
function numUnit(styleSet, propSetprops, num) {
  const propItem = propSetprops && propSetprops[0]
  const fmt = propItem?.format ?? null
  let unit = ''
  let denominator = 1
  if (styleSet.numberunit == 'tenthousand') {
    unit = '万'
    denominator = 10000
  } else if (styleSet.numberunit == 'million') {
    unit = '百万'
    denominator = 1000000
  } else if (styleSet.numberunit == 'billion') {
    unit = '亿'
    denominator = 100000000
  } else if (styleSet.numberunit == 'thousand') {
    unit = 'K'
    denominator = 1000
  } else if (styleSet.numberunit == 'trillion') {
    unit = 'M'
    denominator = 1000000
  } else {
    unit = ''
  }
  const fmtendnum = parseFloat(num / denominator)
  const endnum = parseFloat((num / denominator).toFixed(2))
  const result = fmt ? commonJs.propformat(fmtendnum, fmt) : endnum
  return result + unit
}
function AxisUnit(styleSet, num, fmt) {
  let unit = ''
  let denominator = 1
  const digit = fmt?.digit ?? 1
  if (styleSet.yAxisNumUnit == 'tenthousand') {
    unit = '万'
    denominator = 10000
  } else if (styleSet.yAxisNumUnit == 'million') {
    unit = '百万'
    denominator = 1000000
  } else if (styleSet.yAxisNumUnit == 'billion') {
    unit = '亿'
    denominator = 100000000
  } else if (styleSet.yAxisNumUnit == 'thousand') {
    unit = 'K'
    denominator = 1000
  } else if (styleSet.yAxisNumUnit == 'trillion') {
    unit = 'M'
    denominator = 1000000
  } else {
    unit = ''
  }
  // const fmtendnum = parseFloat(num / denominator)
  const endnum = parseFloat((num / denominator).toFixed(digit))
  return endnum + unit
}
export default commonJs
