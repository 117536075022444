import conditionconfig from './conditionconfig'
import BI_config from '@/utils/config'
import store from '@/store'

let detailJS = {
  behaviorUrl: (_this, item, k, groupskey, opts, chartoption, tabletabidx) => {
    // q = {conditionGroup:{},dimensions:[],event:'',injectedArguments:{},number: 141930,source:'behavior',target:'user',timeRange:{},sid:'',}
    let urlquery = ''
    switch (chartoption.searchCode) {
      case 'behavior':
        urlquery = getBehaviorUrl(item, k, groupskey, chartoption)
        break
      case 'attribute':
        urlquery = getAttributeUrl(item, k, groupskey, chartoption)
        break
      case 'path':
        urlquery = getPathUrl(item, k, groupskey, chartoption)
        break
      case 'association':
        urlquery = getAssociationUrl(item, k, groupskey, chartoption)
        break
      case 'scatter':
        urlquery = getScatterUrl(item, k, groupskey, chartoption)
        break
      case 'retention':
        urlquery = getRetentionUrl(item, k, groupskey, chartoption)
        break
      case 'funnel':
        urlquery = getFunnelUrl(item, k, groupskey, chartoption, tabletabidx)
        break
      case 'template':
        urlquery = getTemplateUrl(item, k, groupskey, chartoption)
        break
      case 'contrast':
        urlquery = getContrastUrl(item, k, groupskey, chartoption)
        break
      case 'segment':
        urlquery = getSegmentUrl(item, k, groupskey, chartoption)
        break
    }
    let detailhref = BI_config.SiteHost + '/Behavior/Detail/' + store.state.projectID + '#' + urlquery
    window.open(detailhref, '_blank')
  },
}

function getBehaviorUrl(item, k, groupskey, chartoption) {
  const reqObj = chartoption.reqObj
  let q = {}
  let editColumn = getMeasures(reqObj).find(x => {
    return k.includes(x.code)
  })
  let conditions = getValidConditions(reqObj.conditionGroup)
  conditions = conditions.filter(x => {
    const codes = x.code.split('|')
    const ret = codes.length == 1 || (codes.length > 1 && codes[1] == editColumn.event)
    return ret
  })

  q.event = editColumn.event
  q.number = item[k]
  q.conditionGroup = {
    operator: reqObj.conditionGroup.operator,
    conditions: conditions,
  }
  q.injectedArguments = reqObj.injectedArguments
  q.target = reqObj.target
  q.source = chartoption.searchCode
  q.sid = q.source + '_' + Math.random()

  let selectedGroups = getSelectedGroups(reqObj)
  let dimValues = []
  let dimensions = selectedGroups.filter(x => {
    let ret = x.code != k
    const codes = x.code.split('|')
    ret = ret && (codes.length == 1 || (codes.length > 1 && codes[1] == editColumn.event))
    return ret
  })
  if (dimensions.length > 0) {
    // var symbol = self.inferSymbols()
    dimValues = dimensions.map((x, i) => {
      var codes = x.code.split('|')
      var alais = x.code == 'eventTime' ? 'day_dim' : codes[0] + '_dim'
      var dim = {
        code: codes[0],
        value: item[alais + '_orig'],
        category: x.category,
      }
      // if (symbol.hasDateDim && i == 0) {
      //   dim.value = item[mapping[column.code].dateDimCode]
      // }
      if (x.category && [2, 4, 5].indexOf(x.category) >= 0) {
        dim.range = x.values.find(t => {
          return t.alias == dim.value
        })
      }
      if (x.value) {
        dim.defineValue = x.value
      }
      if (x.tagvalues) {
        dim.defineValues = x.tagvalues
      }
      return dim
    })
  }
  q.dimensions = dimValues

  q.timeRange = isContrast(k) ? reqObj.timeRange[1] : reqObj.timeRange[0]

  return encodeURIComponent(JSON.stringify(q))
}
function getAttributeUrl(item, k, groupskey, chartoption) {
  const reqObj = chartoption.reqObj
  let q = {}
  q.conditionGroup = {
    operator: 'and',
    conditions: reqObj.conditionGroup.conditions,
  }
  q.number = item[k]
  q.source = chartoption.searchCode
  q.sid = q.source + '_' + Math.random()
  q.target = reqObj.target

  q.selectedGroups = reqObj.selectedGroups.filter(x => {
    return x.code != 'allField' && ((x.type == 'tag' && x.tagvalues.length > 0) || x.type != 'tag')
  })
  q.detailConditions = q.selectedGroups.map(x => {
    let code = x.code
    let op = code == '_tag' ? (item[x.code + '_dim'] == '-' ? 'tag_not_in' : 'tag_in') : 'equals'

    return {
      operator: 'and',
      conditions: [
        {
          code: x.code,
          operator: op,
          values: [item[x.code + '_dim_orig']],
        },
      ],
    }
  })

  return encodeURIComponent(JSON.stringify(q))
}
function getPathUrl(item, k, groupskey, chartoption) {
  let q = chartoption.reqObj
  q.number = item[k]
  q.source = 'path'
  let rowInResult = item
  q.path = rowInResult['path_orig']

  if (q.path.indexOf('<:>') > -1) {
    let nodes = q.path.split('<:>')
    if (nodes[0].indexOf('[*]') > -1) {
      q.pathType = 'start'
    } else {
      q.pathType = 'end'
    }
  } else {
    q.pathType = 'initial'
  }
  q.sid = q.source + '_' + Math.random()
  return encodeURIComponent(JSON.stringify(q))
}
function getAssociationUrl(item, k, groupskey, chartoption) {
  let q = chartoption.reqObj
  q.disableLoading = false
  q.itemValue = item['itemset_a']
  q.detailValue = item['itemset_b']
  q.source = 'Association'
  q.associFlag = k == 'supportcount' ? 1 : 0

  q.sid = q.source + '_' + Math.random()

  return encodeURIComponent(JSON.stringify(q))
}
function getScatterUrl(item, k, groupskey, chartoption) {
  let q = chartoption.reqObj
  q.number = item[k]

  let ksplit = k.split('_').pop()
  q.measureValue = ksplit == 'all' ? 'total' : ksplit
  q.dimensionValue = item[groupskey]
  q.source = 'Scatter'
  q.sid = q.source + '_' + Math.random()

  return encodeURIComponent(JSON.stringify(q))
}
function getRetentionUrl(item, k, groupskey, chartoption) {
  let q = chartoption.reqObj
  q.number = item[k]
  let ksplit = k.split('_').pop()
  q.measure_value = ksplit == 'all' ? 'total' : ksplit
  q.dimension_value = item[groupskey + '_orig']
  q.source = 'Retention'
  q.sid = q.source + '_' + Math.random()

  return encodeURIComponent(JSON.stringify(q))
}

function getFunnelUrl(item, k, groupskey, chartoption, tabletabidx) {
  let envData = {}
  envData.eventDic = {}
  envData.funnelDic = {}
  store.state.envdata.targets[0].events.forEach(item => {
    envData.eventDic[item.code] = item
  })
  store.state.envdata.funnels.forEach(item => {
    envData.funnelDic[item.id] = item
  })
  let q = chartoption.reqObj
  const tab = chartoption.definition.tabs[tabletabidx]

  let dimColumn = [
    {
      code: 'dimension',
      text: chartoption.definition.dimensions && chartoption.definition.dimensions.length > 0 ? chartoption.definition.dimensions[0].text : '总体',
      sortable: true,
      clickable: false,
    },
  ]
  let columns = []
  if (tab.step < 0) {
    columns = chartoption.definition.eventSteps.map(x => {
      return {
        code: 'conversion_count_' + x.step,
        text: envData.eventDic[x.event],
        overall_rate: 'overall_conversion_rate_' + x.step,
        step: x.step,
        sortable: true,
        clickable: true,
        format: 'number',
      }
    })
    columns.push({
      code: 'overall_conversion_rate_last',
      text: '整体转化率',
      overall_rate: 'overall_conversion_rate_last',
      step: chartoption.definition.eventSteps.length + 1,
      sortable: true,
      clickable: false,
      format: 'percentage',
    })
  } else {
    columns = [
      {
        code: 'conversion_count',
        text: '转化人数',
        sortable: true,
        format: 'number',
        clickable: true,
      },
      {
        code: 'conversion_rate',
        text: '转化率',
        sortable: true,
        format: 'percentage',
        clickable: false,
      },
      {
        code: 'churn_count',
        text: '流失人数',
        sortable: true,
        format: 'number',
        clickable: true,
      },
      {
        code: 'churn_rate',
        text: '流失率',
        sortable: true,
        format: 'percentage',
        clickable: false,
      },
    ]
  }
  columns = dimColumn.concat(columns)

  q.step =
    tab.step < 0
      ? columns.find(x => {
          return k.includes(x.code)
        }).step
      : tab.step

  let grp = q.selectedGroups[0]
  let dimensions = []
  if ('projectID' != grp.code) {
    var codes = grp.code.split('|')
    var dim = {
      code: codes.length > 1 ? codes[1] : codes[0],
      value: item['dimension'],
      category: grp.category,
    }
    if (grp.values) {
      dim.range = grp.values.filter(t => {
        return t.alias == dim.value
      })
    }
    if (grp.value) {
      dim.defineValue = grp.value
    }
    if (grp.tagvalues) {
      dim.defineValues = grp.tagvalues
    }
    if (item['dimension'] != '全部') {
      dimensions.push(dim)
    }
  }

  q.number = item[k]
  q.dimensions = dimensions
  q.source = 'Funnel'
  q.type = k
  q.disableLoading = false
  q.sid = q.source + '_' + Math.random()

  return encodeURIComponent(JSON.stringify(q))
}
function getTemplateUrl(item, k, groupskey, chartoption) {
  let q = chartoption.reqObj
  q.number = item[k]
  q.source = 'template'
  q.showColumn = k
  q.sid = q.source + '_' + Math.random()
  q.detailConditions = q.selectedGroups.map(x => {
    let code = x.code.includes('|') ? x.code.split('|')[0] : x.code
    let op = code == '_tag_dim' ? (item[x.code] == '-' ? 'tag_not_in' : 'tag_in') : 'equals'
    return {
      operator: 'and',
      conditions: [
        {
          code: code + '_dim',
          operator: op,
          values: [item[code + '_dim']],
        },
      ],
    }
  })

  return encodeURIComponent(JSON.stringify(q))
}
function getContrastUrl(item, k, groupskey, chartoption) {
  const reqObj = chartoption.reqObj
  let q = {}
  let editColumn = getMeasures(reqObj[0]).find(x => {
    return k.includes(x.code)
  })
  q.event = editColumn.event
  q.number = item[k]
  q.target = reqObj[0].target
  q.source = chartoption.searchCode
  q.sid = q.source + '_' + Math.random()

  let selectedGroups = getSelectedGroups(reqObj[0])
  let dimValues = []
  let dimensions = selectedGroups.filter(x => {
    let ret = x.code != k
    const codes = x.code.split('|')
    ret = ret && (codes.length == 1 || (codes.length > 1 && codes[1] == editColumn.event))
    return ret
  })
  if (dimensions.length > 0) {
    dimValues = dimensions.map((x, i) => {
      var codes = x.code.split('|')
      var alais = x.code == 'eventTime' ? 'day_dim' : codes[0] + '_dim'
      var dim = {
        code: codes[0],
        value: item[alais + '_orig'],
        category: x.category,
      }
      if (x.category && [2, 4, 5].indexOf(x.category) >= 0) {
        dim.range = x.values.filter(t => {
          return t.alias == dim.value
        })
      }
      if (x.value) {
        dim.defineValue = x.value
      }
      if (x.tagvalues) {
        dim.defineValues = x.tagvalues
      }
      return dim
    })
  }

  function processCondition(conditions) {
    let cond = conditions.filter(x => {
      const codes = x.code.split('|')
      const ret = codes.length == 1 || (codes.length > 1 && codes[1] == editColumn.event)
      return ret
    })
    return cond
  }
  //根据左右和分组情况使用不同参数
  if (k.indexOf('_contrast') < 0) {
    q.conditionGroup = reqObj[0].conditionGroup
    q.conditionGroup.conditions = processCondition(q.conditionGroup.conditions)
    q.timeRange = reqObj[0].timeRange[0]
    q.dimensions = dimValues
    q.injectedArguments = reqObj[0].injectedArguments
  } else {
    q.conditionGroup = reqObj[1].conditionGroup
    q.conditionGroup.conditions = processCondition(q.conditionGroup.conditions)
    q.timeRange = reqObj[1].timeRange[0]
    q.dimensions = reqObj[0].selectedGroupTwo == 'all' ? [] : dimValues
    q.injectedArguments = reqObj[1].injectedArguments
  }

  return encodeURIComponent(JSON.stringify(q))
}
function getSegmentUrl(item, k, groupskey, chartoption) {
  const reqObj = chartoption.reqObj
  let q = {}

  return encodeURIComponent(JSON.stringify(q))
}

// 获取指标
function getMeasures(reqObj) {
  let columns = []
  reqObj.selectedEvents.forEach(x => {
    let fields = x.selectedFields.map(s => {
      let codes = s.split(',')
      let code = [...codes, x.code].join('_').toLowerCase()
      return {
        code: code,
        event: x.code,
      }
    })
    columns = columns.concat(fields)
  })
  return columns
}
function getValidConditions(conditionGroups) {
  let operators = {}
  conditionconfig.operators.forEach(x => {
    operators[x.code] = x
  })
  let conditions = conditionGroups.conditions.filter(x => {
    if (x.operator == 'relativedate') {
      //相对日期 有效条件检查
      var cval = x.values[0].split(',')
      if (cval[0] == 'gt' || cval[0] == 'lt') {
        return cval.length > 1
      } else if (cval[0] == 'between') {
        return cval.length > 2
      } else {
        return true
      }
    } else {
      var number = x.operator && operators[x.operator] ? operators[x.operator].number : 1
      return (
        x.code &&
        x.operator &&
        ((number > 0 &&
          x.values.length > 0 &&
          !x.values.find(v => {
            return v == null || v == undefined
          })) ||
          (number == 0 && x.values.length == 0))
      )
    }
  })

  return conditions
}
function getSelectedGroups(reqObj) {
  const inject = reqObj.injectedArguments
  // let groups = inject && inject.selectedGroups ? inject.selectedGroups : []
  let groups = reqObj.selectedGroups
  return groups
}

function isContrast(k) {
  if (k.includes('_contrast') || k.includes('_last') || k.includes('_circle')) {
    return true
  } else {
    return false
  }
}

export default detailJS
