import router from './router'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { getToken } from '@/utils/auth' // get token from cookie
import BI_config from '@/utils/config'


NProgress.configure({ showSpinner: false }) // NProgress Configuration

router.beforeEach(async(to, from, next) => {
  // start progress bar
  NProgress.start()
  // determine whether the user has logged in
  const hasToken = getToken()
  if (hasToken) {
    next()
  }else{
    
    location.href = `${BI_config.baseLogin}?callbackUrl=${encodeURIComponent(location.href)}`
  }
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})
