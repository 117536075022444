<template>
  <div id="app" :class="isMobile ? 'isMobile' : 'isWeb'">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'app',
  data() {
    return {
      isMobile: false,
    }
  },
  components: {},
  created() {},
  methods: {
    init() {
      const ua = navigator.userAgent
      const ipad = ua.match(/(iPad).*OS\s([\d_]+)/),
        isIphone = !ipad && ua.match(/(iPhone\sOS)\s([\d_]+)/),
        isAndroid = ua.match(/(Android)\s+([\d.]+)/),
        isMobile = isIphone || isAndroid
      //判断
      if (isMobile) {
        this.isMobile = true
        document.getElementsByTagName('body')[0].className += ' mobilebody'
        this.$store.commit('setismobile', true)
      } else {
        if (!(window.navigator.userAgent.indexOf('Chrome') && window.chrome)) {
          if (!window.localStorage.getItem('judgeChrome')) {
            this.$confirm('为了最优的产品体验，建议您下载使用Chrome（谷歌）浏览器', {
              confirmButtonText: '去下载',
              cancelButtonText: '继续使用',
              showClose: false,
              closeOnClickModal: false,
            })
              .then(() => {
                window.open(
                  'https://www.baidu.com/s?wd=chrome%E6%B5%8F%E8%A7%88%E5%99%A8%E5%AE%98%E7%BD%91%E4%B8%8B%E8%BD%BD&rsv_spt=1&rsv_iqid=0xde4e974d0033f4b5&issp=1&f=3&rsv_bp=1&rsv_idx=2&ie=utf-8&rqlang=cn&tn=baiduhome_pg&rsv_enter=0&rsv_dl=ts_2&oq=chrome%25E6%25B5%258F%25E8%25A7%2588%25E5%2599%25A8%25E5%25AE%2598%25E7%25BD%2591%25E4%25B8%258B%25E8%25BD%25BD&rsv_t=f78eVFNyiubMLbInPXupZLRKQDzTm%2FbLruD2hd1Gx%2BgIosB5SAweCOvBtWrjII%2FGgD3G&rsv_btype=t&rsv_pq=d6bca8df006ae80a&prefixsug=chrome%25E6%25B5%258F%25E8%25A7%2588%25E5%2599%25A8%25E5%25AE%2598%25E7%25BD%2591%25E4%25B8%258B%25E8%25BD%25BD&rsp=2'
                )
              })
              .catch(() => {
                window.localStorage.setItem('judgeChrome', 'true')
              })
          }
        }
      }
    },
  },
  mounted() {
    this.init()
  },
}
</script>
<style>
@import '~@/assets/iconfont/iconfont.css';
</style>
<style lang="scss">
@import '@/assets/style/css.scss';

* {
  margin: 0;
  padding: 0;
  list-style: none;
  box-sizing: border-box;
  text-decoration: none;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  width: 100%;
  height: 100%;
  min-height: 100%;
  &.isMobile {
    height: 100%;
  }
}

.el-scrollbar {
  > .el-scrollbar__bar {
    opacity: 1 !important;
  }
}
</style>
