import detailJS from './pagejs/detailUrl'
import BI_config from '@/utils/config'
import commonJs from './common'
import pinyin from 'chinese-to-pinyin'
import store from '@/store'

let commonJs2 = {
  //charts 图表悬浮框
  getTooltip: (param, data, pencentage = false, isenableStack = false, fmt, propSetprops = []) => {
    let myparam = []
    param.forEach((x, i) => {
      let item = data[x.seriesIndex]
      let last_item = x.seriesIndex > 0 && isenableStack ? data[x.seriesIndex - 1] : null
      if (item) {
        x.code = item.code
        x.measure = item.measure
        x.displayName = item.name
        x.displayType = item.displayType
        x.contrastType = item.contrastType ? item.contrastType : 0
        x.yAxisIndex = item.yAxisIndex
        x.lastval = last_item ? last_item.data : null
        const prp = propSetprops.find(p => p.alias === item.alias || item.alias.includes(p.alias + '_'))
        x.fmt = prp?.format ?? null
        myparam.push(x)
      }
    })
    let cA = [
      ...new Set(
        myparam.map(x => {
          return x.code
        })
      ),
    ]
    let codeGroups = []
    cA.map((tag, i) => {
      let list = myparam.filter(x => {
        return x['code'] == tag
      })
      codeGroups[i] = list
      codeGroups[i]['key'] = tag
    })
    let htmls = []
    codeGroups.forEach((g, gidx) => {
      let html = ''
      if (gidx == 0) {
        let groupTitle = g[0].name
        html = "<h3 style='font-weight:normal;padding:4px 5px;'>" + groupTitle + '</h3>'
      }
      if (g[0].measure != g[0].displayName) {
        let titleHtml = g[0].measure
        html = titleHtml ? html + "<h3 style='font-weight:normal;padding:4px 5px;'>" + titleHtml + '</h3>' : html
      }
      // const displayType = pencentage ? 'PERCENTAGE_DIRECT' : g[0].displayType
      g.forEach(n => {
        const displayType = n.displayType
        let value = n.value
        //线图堆积时
        if (value != '-' && n.lastval) {
          value = n.lastval[n.dataIndex] === '-' ? value : value - n.lastval[n.dataIndex]
        }
        let span = "<span style='width:6px;height:6px;border-radius:4px;margin:8px 5px 0;float:left;background:" + n.color + ";'></span>"
        let val =
          pencentage && n.yAxisIndex == 1 ? (value == '-' ? value : `${value}%`) : commonJs.propnumformat(store.state.pageunit, value, '', displayType, n.fmt)
        html += "<div style='padding:2px 0 3px;text-align:left;'>" + span + n.displayName + ' : ' + val + '&nbsp&nbsp' + '</div>'
      })
      htmls.push(html)
    })
    let tooltipHtml = "<div style='max-height:300px;overflow-y:auto;padding:5px;border-radius:4px;'>" + htmls.join('') + '</div>'
    return tooltipHtml
  },
  //参数转Base64
  setUrl: p => {
    return btoa(unescape(encodeURIComponent(JSON.stringify(p))))
  },
  //Base64转参数
  getUrl: url => {
    url = url.substring(1, url.length)
    try {
      return JSON.parse(decodeURIComponent(escape(atob(url))))
    } catch (error) {
      return null
    }
  },
  //千分位
  thousandBitNum: num => {
    num = typeof num == 'number' ? num.toString() : num
    if (num.indexOf('.') !== -1) {
      let nums = num.split('.')
      const num0 = nums[0].replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
      num = String(num0) + '.' + nums[1]
    } else {
      num = num.replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
    }
    return num
  },

  toDetail: (_this, item, k, col, groupskey, opts, chartoption, orginResult, allgroups, chartDataItem) => {
    if (chartoption.version == '2.0') {
      let analysisType = chartoption.searchCode
      //高级分析判断每个点击的卡片
      if (analysisType == 'advance') {
        const enablePivot = opts.enablePivot
        const cardid =
          enablePivot === 'closed'
            ? k.split('_')[0]
            : enablePivot === 'full'
            ? item.prop.split('_')[0]
            : k
                .split('[&]')
                .pop()
                .split('_')[0]
        const cards = chartoption.reqObj.parameter.analyses.find(x => {
          return x.id == cardid
        })
        let type = cards.category
        const defin = JSON.parse(cards.definition)
        if (cards.category == 'template') {
          type = {
            category: cards.category,
            usage: defin.parameter.usage,
          }
        }
        commonJs2.onRedirection(_this, item, k, col, groupskey, type, defin.parameter, opts, chartoption, chartoption.reqObj.parameter, chartDataItem)
      } else {
        if (analysisType == 'template') {
          analysisType = {
            category: analysisType,
            usage: chartoption.reqObj.parameter.usage,
          }
        }
        commonJs2.onRedirection(_this, item, k, col, groupskey, analysisType, chartoption.reqObj.parameter, opts, chartoption, '', chartDataItem)
      }
    } else {
      detailJS.behaviorUrl(_this, item, k, groupskey, opts, chartoption, orginResult)
    }
  },
  //跳转
  onRedirection(_this, item, k, col, groupskey, type, parameter, opts, chartoption, isadvance, chartDataItem) {
    const orginResult = chartoption.orginResult
    if (!_this.$commonJs._point('BI_F0003')) {
      _this.$alert(`您的权限不足, 无法查看明细`, '提示', {
        confirmButtonText: '我知道了',
      })
      return false
    }
    let analysisType = type.category ? type.category : type
    let enablePivot = opts.enablePivot || opts?.styleSet?.enablePivot
    const cardid =
      enablePivot === 'closed'
        ? Number(k.split('_')[0])
        : enablePivot === 'full'
        ? Number(item.prop.split('_')[0])
        : Number(
            k
              .split('[&]')
              .pop()
              .split('_')[0]
          )
    let rows = orginResult.rows
    let columns = []
    if (isadvance) {
      // columns = chartoption.reqObj.parameter.columns
      //   .filter(x => {
      //     return cardid === x.bookmarkID
      //   })
      //   .map(x => {
      //     x.id_alias = x.bookmarkID + '_' + x.alias
      //     return x
      //   })

      columns = orginResult.columns.filter(x => {
        return x.alias.includes(cardid)
      })
    } else {
      columns = orginResult.columns
    }
    let groups = JSON.parse(JSON.stringify(parameter.groups || []))
    const injectedArguments = chartoption.reqObj.injectedArguments
    if (injectedArguments && injectedArguments.selectedGroups) {
      groups.forEach(g => {
        if (g.code == 'time' && g.mode == 'Normal') {
          g.preset = injectedArguments.selectedGroups[0].value
          g.ranges = null
        }
      })
    }
    // 单条数据
    let row = item
    if (enablePivot === 'full') {
      row = rows[k.split('_')[1]]
    }
    if (enablePivot == 'part') {
      let currow = rows.filter(x => {
        let sys = item[groupskey[0]] + '[|]' + k.split('[&]')[0]
        return sys == x.sys_gen_key
      })
      row = currow[0]
    }
    let query = {}
    // 标签回跳搭建地址
    query.referer = window.location.href

    let enablealias = enablePivot === 'full' ? item['prop'] : enablePivot === 'part' ? k.split('[&]')[1] : k
    if (isadvance) {
      const _idx = enablealias.indexOf('_')
      enablealias = enablealias.substring(_idx + 1)
    }
    //点击列
    k = enablePivot === 'full' ? item['prop'] : enablePivot === 'part' ? k.split('[&]')[1] : k
    let curevent = columns.find(x => {
      return isadvance ? x.alias == k : x.alias == k
    })
    if (analysisType == 'behavior') {
      query.filter = parameter.filter
      query.prop = {
        alias: enablealias,
        value: item[k],
      }
      // 事件id
      // 事件名称
      query.eventID = curevent.eventID
      query.actor = curevent.clickableType
      let event = parameter.events.find(x => {
        return curevent.eventID == x.id
      })
      let measure = event.measures.find(x => {
        return curevent.code == x.code
      })
      query.eventName = measure.text
      // 对比类型
      let contrastType = 'Current'
      if (k.match(/_last$/)) {
        contrastType = 'Last'
      }
      if (k.match(/_circle$/)) {
        contrastType = 'Circle'
      }
      if (k.match(/_contrast$/)) {
        contrastType = 'Contrast'
      }
      query.contrastType = isadvance ? 'Current' : contrastType
      // 时间
      if (isadvance) {
        query.timeRanges = commonJs2.getAdvanceTimeRanges(chartoption.data.timeRanges, contrastType)
      } else {
        query.timeRanges = chartDataItem ? chartDataItem.timeRange : parameter.timeRanges
        if (injectedArguments && injectedArguments.timeRange) {
          query.timeRanges = injectedArguments.timeRange
        }
      }

      // groups 分组数据
      query.groups = groups.filter(x => !x.eventID || x.eventID == query.eventID)
      commonJs2.getFormatValue(cardid, query.groups, analysisType, columns, contrastType, row, isadvance)

      if (query.filter) {
        query.filter.conditions = query.filter.conditions.filter(x => !x.eventID || x.eventID == query.eventID)
      }
    } else if (analysisType == 'attribute') {
      query.filter = parameter.filter
      query.prop = {
        alias: enablealias,
        value: item[k],
      }
      // 属性分析 user_fixed_user_mid_distinct_circleRate 取user
      query.actor = curevent.clickableType
      query.groups = groups
      commonJs2.getFormatValue(cardid, query.groups, analysisType, columns, '', row, isadvance)
    } else if (analysisType == 'segment') {
      query.filter = parameter.filter
      query.prop = {
        alias: enablealias,
        value: item[k],
      }
      query.actor = curevent.clickableType
      query.uniqueID = orginResult.uniqueID
      query.segmentName = chartoption.reqObj.title
    } else if (analysisType == 'template') {
      query = JSON.parse(JSON.stringify(parameter))
      // 高级分析
      if (isadvance) {
        let contrastType = 'Current'
        if (k.match(/_last$/)) {
          contrastType = 'Last'
        }
        if (k.match(/_circle$/)) {
          contrastType = 'Circle'
        }
        if (k.match(/_contrast$/)) {
          contrastType = 'Contrast'
        }
        query.components.forEach(x => {
          if (x.type === 'TimePicker') {
            x.timeRange = commonJs2.getAdvanceTimeRanges(chartoption.data.timeRanges, contrastType, 'template')
          }
        })
      } //如果是卡片组合内的 时间应改为卡片组合的时间
      else if (chartDataItem) {
        query.components.forEach(x => {
          if (x.type === 'TimePicker') {
            x.timeRange = chartDataItem.timeRange[0]
          }
        })
      } else {
        //修改时间查询
        if (injectedArguments && injectedArguments.timeRange) {
          query.components.forEach(x => {
            if (x.type === 'TimePicker') {
              x.timeRange = injectedArguments.timeRange[0]
            }
          })
        }
      }
      query.actor = curevent.clickableType
      query.name = chartoption.reqObj.title
      const usage = type.usage
      let tempgroup = columns.filter(x => {
        return x.usage == 'Group'
      })

      if (usage == 'Common') {
        let posgroup = commonJs2.getTemplateFormatValue(tempgroup, row, isadvance)

        let position = {
          type: 'Position',
          positions: [
            {
              alias: enablealias,
              value: item[k],
              usage: 'Measure',
            },
            ...posgroup,
          ],
        }
        query.components.push(position)
      } else if (usage == 'Path') {
        let position = {
          type: 'Position',
          positions: [
            {
              alias: enablealias,
              value: item[k],
              usage: 'Measure',
            },
            {
              alias: 'path',
              value: item['path_raw'],
              usage: 'Group',
            },
          ],
        }
        query.components.push(position)
      } else if (usage == 'Association') {
        let posgroup = commonJs2.getTemplateFormatValue(tempgroup, row, isadvance)
        let position = {
          type: 'Position',
          positions: [
            {
              alias: enablealias,
              value: item[k],
              usage: 'Measure',
            },
            ...posgroup,
          ],
        }
        query.components.push(position)
      } else if (usage == 'Scatter') {
        let posgroup = commonJs2.getTemplateFormatValue(tempgroup, row, isadvance)
        if (k == 'total') {
          let position = {
            type: 'Position',
            positions: [
              {
                alias: enablealias,
                value: item[k],
                usage: 'Measure',
              },
              ...posgroup,
            ],
          }
          query.components.push(position)
        } else {
          const scatter_event = parameter.components.find(x => {
            return x.type == 'Scatter'
          }).events
          const preset = scatter_event[0].measures[0].preset

          let groupval = col['fullAlias'].split('duration_')[1]
          let position = {
            type: 'Position',
            positions: [
              {
                alias: 'count',
                value: item[k],
                usage: 'Measure',
              },
              {
                alias: 'm0',
                value: ['discrete', 'accumulate'].includes(preset) ? Number(groupval.replace(/[^0-9]/gi, '')) : groupval,
                usage: 'Group',
              },
              ...posgroup,
            ],
          }
          query.components.push(position)
        }
      } else if (usage == 'Retention') {
        let posgroup = commonJs2.getTemplateFormatValue(tempgroup, row, isadvance)
        if (k == 'total') {
          let position = {
            type: 'Position',
            positions: [
              {
                alias: enablealias,
                value: item[k],
                usage: 'Measure',
              },
              ...posgroup,
            ],
          }
          query.components.push(position)
        } else {
          let position = {
            type: 'Position',
            positions: [
              {
                alias: 'count',
                value: item[k],
                usage: 'Measure',
              },
              {
                alias: 'duration',
                value: Number(col['fullAlias'].split('duration_')[1]),
                usage: 'Group',
              },
              ...posgroup,
            ],
          }
          query.components.push(position)
        }
      }
    }
    let prefilter = ''
    if (chartDataItem && chartDataItem.searchCode === 'combination') {
      prefilter = chartDataItem?.reqObj?.injectedArguments?.prefilter
    } else {
      prefilter = injectedArguments?.prefilter
    }
    if (analysisType !== 'segment' && prefilter) {
      query.prefilter = prefilter
    }
    let urlquery = commonJs2.setUrl(query)
    let routes = _this.$router.resolve({
      path: `${BI_config.analysisProxy}/${analysisType}/entityList/${store.state.projectID}/${query.actor}`,
      hash: urlquery,
    })
    window.open(routes.href)
  },
  // 时间格式化的取正确的值
  getFormatValue(cardid, groups, analysisType, columns, contrastType, row, isadvance) {
    groups.forEach(group => {
      // 分组会返回不同的参数
      let alias = '',
        alias_contrastType = '',
        alias_row = ''

      if (isadvance) {
        const advancegroup = isadvance.columns.find(x => {
          return x.code == group.code && x.bookmarkID == cardid
        })
        alias = `${advancegroup.bookmarkID + '_' + advancegroup.alias}`
      } else {
        alias = group.alias
          ? group.alias
          : columns.find(x => {
              return x.code == group.code
            }).alias
      }

      if (group.code == 'time') {
        alias_contrastType = alias + `_${contrastType.toLowerCase()}`
      }
      alias_row = `${alias}_raw`
      if (row[alias_contrastType]) {
        group.value = row[alias_contrastType]
      } else if (row[alias_row]) {
        group.value = row[alias_row]
      } else {
        group.value = row[alias]
      }
      delete group.alias
      delete group.mode
      delete group.dataType
    })
  },
  getTemplateFormatValue(tempgroup, item, isadvance) {
    let group = tempgroup.map(x => {
      const alias_current = `${x.alias}_current`
      const alias_raw = `${x.alias}_raw`
      return {
        alias: isadvance ? x.alias.substring(x.alias.indexOf('_') + 1) : x.alias,
        value: item[alias_current] ? item[alias_current] : item[alias_raw] ? item[alias_raw] : isadvance ? item[x.alias] : item[x.alias],
        usage: 'Group',
      }
    })
    return group
  },

  //辅助线
  initMarkLine(_this, chartColor, config, copyRows, contrastLabels, labelColor) {
    let num_pattern = /^(-?\d+)(\.\d+)?$/
    let styleSet = config.styleSet

    if (styleSet.subline == 'fixed' && num_pattern.test(styleSet.sublineVal)) {
      setMarkLine(styleSet.sublineVal, '固定值', labelColor, chartColor)
    }
    if (styleSet.subline == 'measureField') {
      var values = copyRows.map(x => x[styleSet.sublineField])
      var num = 0
      var name = ''
      if (styleSet.sublineAggregation == 'max') {
        num = Math.max.apply(null, values)
        name = '最大值'
      } else if (styleSet.sublineAggregation == 'min') {
        num = Math.min.apply(null, values)
        name = '最小值'
      } else if (styleSet.sublineAggregation == 'avg') {
        if (values.length > 0) {
          num = _this.$lo_.sum(values) / values.length
        }
        name = '平均值'
      } else {
        num = median(values)
        name = '中位数'
      }
      setMarkLine(num, name, labelColor, chartColor)
    }
    if (styleSet.summaryLine && styleSet.summaryLine != 'none') {
      let seriesdatas = _this.$lo_.groupBy(config.seriesdata, 'contrastType')
      Object.values(seriesdatas).forEach(series => {
        let xx = _this.$lo_.groupBy(series, 'measure')
        Object.values(xx).forEach(yy => {
          var data = []
          var datas = yy.map(x => x.data)
          var zipped = _this.$lo_.zip(...datas)
          zipped.forEach(z => {
            z = z.filter(d => d != undefined)
            var num = _this.$lo_.sum(z)
            if (styleSet.summaryLine == 'avg') {
              num = Math.round((num / (z.length || 1)) * 100) / 100
            }
            data.push(num)
          })
          var newSeries = _this.$lo_.cloneDeep(yy[0])
          newSeries.data = data
          newSeries.isAggregation = true //聚合数据
          newSeries.displayName = styleSet.summaryLine == 'sum' ? `总计` : '平均值'
          newSeries.displayName = (contrastLabels[newSeries.contrastType] || '') + newSeries.displayName
          newSeries.measure = yy[0].measure
          newSeries.name = `${newSeries.displayName}-${newSeries.measure}`
          config.seriesdata.push(newSeries)
          config.legend.push(newSeries.name)
        })
      })
    }

    function setMarkLine(num, name, labelColor, chartColor) {
      var markLine = {
        silent: false,
        data: [
          {
            name: name,
            label: {
              show: true,
              position: 'end',
              formatter: function(v) {
                return _this.$commonJs.formatnum(store.state.basicInfo.project.axisUnit, v.value, styleSet.enablePencentage)
              },
            },
            lineStyle: {
              normal: {
                color: chartColor[0],
                width: 1,
                type: 'dashed',
              },
            },
          },
        ],
        label: {
          show: true,
          fontSize: 12,
          color: labelColor,
        },
      }
      if (styleSet.direction == 'horizontal') {
        markLine.data[0].xAxis = num
      } else {
        markLine.data[0].yAxis = num
      }
      if (config.seriesdata[0]) {
        config.seriesdata[0].markLine = markLine
      }
    }
  },
  //拼音搜索
  matchSoftly: (source, candidate) => {
    console.time()
    if (source && candidate && source.toLowerCase().includes(candidate.toLowerCase())) return true

    console.timeEnd()
    console.time()
    let parsed = pinyin(source, {
      keepRest: true,
      removeTone: true,
      removeSpace: true,
    })
    console.timeEnd()
    return parsed.toLowerCase().includes(candidate.toLowerCase())
  },

  //高级分析的 事件分析和模板分析 时间取值
  getAdvanceTimeRanges(times, contrastType, template) {
    let timeranges = []
    const list = times.filter(x => {
      return x.contrastType && contrastType && x.contrastType.toLowerCase() === contrastType.toLowerCase()
    })
    timeranges = list.map(x => {
      return {
        mode: 'Absolute',
        start: x.start,
        end: x.end,
      }
    })
    return template ? timeranges[0] : timeranges
  },
  //函数防抖 和 函数节流 防止多次点击按钮
  debounce(fn, delay, immediate) {
    let timer = null
    return function() {
      let context = this
      let args = arguments
      clearTimeout(timer)
      if (immediate) {
        //连续动作只初次生效
        //如果启动了定时器 表示已经执行过，不再执行
        !timer && fn.apply(context, args) //启动定时器标识 delay时间后的动作才能执行下一次
        timer = setTimeout(function() {
          timer = null
        }, delay)
      } else {
        //连续动作只末次生效
        timer = setTimeout(function() {
          fn.apply(context, args)
        }, delay)
      }
    }
  },
  // 是否手机端
  _isMobile() {
    let flag = navigator.userAgent
    if (
      flag.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
    ) {
      return true
    } else {
      return false
    }
  },
}

function sortNumber(a, b) {
  return a - b
}
function median(arr) {
  arr.sort(sortNumber)
  //求中位数
  if (arr.length % 2 == 0) {
    return (arr[arr.length / 2 - 1] + arr[arr.length / 2]) / 2
  } else {
    return arr[Math.floor(arr.length / 2)]
  }
}

export default commonJs2
