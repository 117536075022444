import request from '@/utils/request2'

//查询订阅
export function getSubscription(id) {
  return request({
    url: '/datasubscription?planID=' + id,
    method: 'get',
  })
}
// 获取商场订阅信息
export function getChannelConfig(params) {
  return request({
    url: '/datasubscription/channelConfig',
    method: 'get',
    params,
  })
}

// 获取可绑定数据面板
export function getSubscripDashboards(params) {
  return request({
    url: '/datasubscription/dashboards',
    method: 'get',
    params,
  })
}

// 获取数据面板订阅信息
export function getDashboardSubscrip(id) {
  return request({
    url: '/datasubscription/' + id,
    method: 'get',
  })
}

//新增订阅
export function postSubscription(data) {
  return request({
    url: '/datasubscription',
    method: 'post',
    data,
  })
}

//更新订阅
export function putSubscription(data) {
  return request({
    url: '/datasubscription',
    method: 'put',
    data,
  })
}