import Vue from 'vue'
import App from './App.vue'
import router from './router'

import '@/plugin/importjs.js'

// 滚动条
import vuescroll from 'vuescroll'
Vue.use(vuescroll, {
  ops: {
    bar: {
      background: 'rgba(0, 0, 0, 0.2)',
      onlyShowBarOnScroll: false,
    },
  }, // 在这里设置全局默认配置
  name: 'myScroll', // 在这里自定义组件名字，默认是vueScroll
})

import store from './store'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

import './permission'

//使用pl-table 使用格式外层必须包含一层父级元素，且必须设置高度
// 数据为 :datas
import plTable from 'pl-table'
import 'pl-table/themes/index.css'
// import 'pl-table/themes/plTableStyle.css'
Vue.use(plTable)

import dayjs from 'dayjs'
import WeekDay from 'dayjs/plugin/weekday'
import 'dayjs/locale/zh-cn'
dayjs.extend(WeekDay)
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
dayjs.extend(isSameOrBefore)
dayjs.locale('zh-cn')
Vue.prototype.moment = dayjs

import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts
import 'echarts-wordcloud'

import weui from 'weui.js'
import 'weui'
Vue.prototype.$weui = weui

import axios from 'axios'
Vue.prototype.$axios = axios
Vue.prototype.$envdata = null

import lo_ from 'lodash'
Vue.prototype.$lo_ = lo_

import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)

//定义全局 系统颜色和 计数单位
// Vue.prototype.$pagecolor = null
Vue.prototype.$pageunit = null

Vue.prototype.$basicInfo = null

Vue.config.productionTip = false

import commonJs from '@/script/common.js'
Vue.prototype.$commonJs = commonJs

import commonJs2 from '@/script/common2.js'
Vue.prototype.$commonJs2 = commonJs2

Vue.prototype.$eventBus = new Vue()

import BI_config from '@/utils/config'
Vue.prototype.$TenantMode = BI_config.tenantMode
Vue.prototype.$EnvIsMallcoo = BI_config.tenantMode == 'Mallcoo'

// 埋点方法
import { trackView } from '@/script/buriedPoint/index.js'
Vue.prototype.$trackView = trackView

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
