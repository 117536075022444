const conditionconfig = {
  operators: [
    {
      code: 'equals',
      text: '等于',
      number: 1,
      template: '{0}={1}',
    },
    {
      code: 'notequals',
      text: '不等于',
      number: 1,
      template: '{0}<>{1}',
    },
    {
      code: 'contains',
      text: '包含',
      number: 1,
      template: 'instr({0}, {1})>0',
    },
    {
      code: 'notcontains',
      text: '不包含',
      number: 1,
      template: 'instr({0}, {1})=0',
    },
    {
      code: 'regexmatch',
      text: '正则匹配',
      number: 1,
      template: 'regexp_like({0},{1})=true',
    },
    {
      code: 'regexnotmatch',
      text: '正则不匹配',
      number: 1,
      template: 'regexp_like({0},{1})=false',
    },
    {
      code: 'hasvalue',
      text: '有值',
      number: 0,
      template: '{0} is not null',
    },
    {
      code: 'hasvalueWithoutempty',
      text: '有值',
      number: 0,
      template: "({0} is not null and {0} !='')",
    },
    {
      code: 'novalue',
      text: '无值',
      number: 0,
      template: '{0} is null',
    },

    {
      code: 'lessthan',
      text: '小于',
      number: 1,
      template: '{0}<{1}',
    },
    {
      code: 'lessthanorequals',
      text: '小于等于',
      number: 1,
      template: '{0}<={1}',
    },
    {
      code: 'greaterthan',
      text: '大于',
      number: 1,
      template: '{0}>{1}',
    },
    {
      code: 'greaterthanorequals',
      text: '大于等于',
      number: 1,
      template: '{0}>={1}',
    },
    {
      code: 'inrange',
      text: '区间',
      number: 2,
      template: '({0} between {1} and {2})',
    },
    {
      code: 'istrue',
      text: '为真',
      number: 0,
      template: '{0} = true',
    },
    {
      code: 'isfalse',
      text: '为假',
      number: 0,
      template: '{0} = false',
    },
    {
      code: 'in',
      text: '在...之间',
      number: 1,
      template: '{0} in ({1})',
    },
    {
      code: 'notin',
      text: '不在...之间',
      number: 1,
      template: '({0} not in ({1}) or {0} is null)',
    },
    //{
    //    "code": "tag_in",
    //    "text": "等于",
    //    "number": 1,
    //    "template": "udf.has({0},{1}) is not null"
    //},
    {
      code: 'tag_in',
      text: '在...之间',
      number: 1,
      template: '(udf.has({0},{1}) is not nulll)',
    },
    {
      code: 'tag_forall',
      text: '同时满足',
      number: 1,
      template: '({0} not in ({1}) or {0} is null)',
    },
    {
      code: 'tag_not_in',
      text: '同时不满足',
      number: 1,
      template: '({0} not in ({1}) or {0} is null)',
    },
    {
      code: 'day_between',
      text: '区间',
      number: 2,
    },
    {
      code: 'daysinrange',
      text: '日度范围',
      number: 2,
      type: 'string',
    },
    {
      code: 'relativedate',
      text: '相对日期',
      number: 1,
      type: 'string',
    },
    {
      code: 'distance_range',
      text: '距离范围',
      number: 1,
    },
    {
      code: 'distance_gt',
      text: '距离大于',
      number: 1,
    },
    {
      code: 'distance_gte',
      text: '距离大于等于',
      number: 1,
    },
    {
      code: 'distance_lt',
      text: '距离小于',
      number: 1,
    },
    {
      code: 'distance_lte',
      text: '距离小于等于',
      number: 1,
    },
    {
      code: 'per_increase',
      text: '每增加',
      number: 1,
    },
  ],
  valueTypeToOperators: [
    {
      valueType: 'string',
      operators: ['equals', 'notequals', 'contains', 'notcontains', 'regexmatch', 'regexnotmatch', 'in', 'notin', 'hasvalueWithoutempty', 'novalue'],
      displayControls: [
        {
          code: 'tagsinput',
          mode: 'single',
          apply: ['equals', 'notequals', 'contains', 'notcontains'],
        },
        {
          code: 'tagsinput',
          mode: 'multiple',
          apply: ['in', 'notin'],
        },
        {
          code: 'textbox',
          apply: ['regexmatch', 'regexnotmatch'],
        },
      ],
    },
    {
      valueType: 'int',
      operators: ['equals', 'notequals', 'lessthan', 'lessthanorequals', 'greaterthan', 'greaterthanorequals', 'inrange', 'hasvalue', 'novalue'],
    },
    {
      valueType: 'double',
      operators: ['equals', 'notequals', 'lessthan', 'lessthanorequals', 'greaterthan', 'greaterthanorequals', 'inrange', 'hasvalue', 'novalue'],
    },
    {
      valueType: 'bool',
      operators: ['istrue', 'isfalse'],
    },
    {
      valueType: 'date',
      operators: ['day_between', 'hasvalue', 'novalue', 'daysinrange', 'relativedate'],
    },
    {
      valueType: 'tag',
      operators: ['tag_in', 'tag_in', 'tag_forall', 'tag_not_in'],
      displayControls: [
        //{
        //    "code": "tagsinput",
        //    "mode": "single",
        //    "apply": ["tag_in"]
        //},
        {
          code: 'tagsinput',
          mode: 'multiple',
          apply: ['tag_in', 'tag_forall', 'tag_not_in'],
        },
      ],
    },
    {
      valueType: 'geo',
      operators: ['distance_range', 'distance_gt', 'distance_gte', 'distance_lt', 'distance_lte'],
      displayControls: [
        {
          code: 'geoinput',
          apply: ['distance_range', 'distance_gt', 'distance_gte', 'distance_lt', 'distance_lte'],
        },
      ],
    },
    {
      valueType: 'sum',
      operators: [
        'equals',
        'notequals',
        'lessthan',
        'lessthanorequals',
        'greaterthan',
        'greaterthanorequals',
        'inrange',
        'hasvalue',
        'novalue',
        'per_increase',
      ],
    },
  ],
}

export default conditionconfig
