import BI_config from '@/utils/config'
import store from '@/store'
import { getToken, hashCode } from '@/utils/auth'
import Vue from 'vue'
import axios from 'axios'
let myv = new Vue()

var service = axios.create({
  baseURL: BI_config.baseApi + '/api',
  timeout: 160000,
  headers: {
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    'Accept-Language': 'zh-cn',
    'Mallcoo-BI-Source': 'dashboard2.0',
    'Mallcoo-BI-Authorization': 'mp_session=' + getToken() || '',
  },
})
service.interceptors.request.use(
  function(config) {
    config.cancelToken = new axios.CancelToken(cancel => {
      store.commit('pushToken', {
        cancelToken: cancel,
      })
    })
    return config
  },
  function(error) {
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
    let url = response.config.url
    if (url.includes('/dashboard/search') || url.includes('/advance/search')) {
      if (response.headers['r-traceid']) {
        postTrace(response.headers)
      }
    }
    if (response.data.code >= 20000 && response.data.data) {
      //销控
      MpGoHandle.init(response.data.data)
    }
    return response
  },
  error => {
    if (error.response) {
      let url = error.response.config.url
      let data = error.response.data
      if (error.response.headers['r-traceid']) {
        postTrace(error.response.headers)
      }
      if (data.code === 401) {
        location.href = `${BI_config.baseLogin}?callbackUrl=${encodeURIComponent(location.href)}`
      }
      let code = hashCode(data.message)
      if (!store.state.errorCounter.has(code)) {
        store.state.errorCounter.add(code)
        if ((!url.includes('/dashboard/search') && !url.includes('/advance/search') && !url.includes('/bookmark')) || url.includes('bookmarkPosition')) {
          //除了 apiv2/dashboard/search 的接口报错弹框提示
          myv.$alert(data.message, '提示', {
            confirmButtonText: '确定',
            type: 'warning',
            callback: action => {
              setTimeout(() => {
                store.state.errorCounter.delete(code)
              }, 500)
            },
          })
        }
      }
    }
    // 接口异常
    return Promise.reject(error) // 返回接口返回的错误信息
  }
)

let postTrace = headers => {
  axios
    .post(
      `${BI_config.baseApi}/api/trace`,
      { referer: window.location.href },
      {
        headers: {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          'R-TraceId': headers['r-traceid'],
        },
      }
    )
    .then(res => {})
}

//高级分析需要把 R-TraceId 串起来， 多个卡片请求的时候 为避免混乱 根据卡片id 放到对象里
let res_Header_arr = []

export default function request(opts, type) {
  let opts1 = { method: type, ...opts }
  if (store.state.projectID) {
    service.defaults.headers['Mallcoo-BI-ProjectID'] = store.state.projectID
  }
  if (store.state.system) {
    service.defaults.headers['Mallcoo-BI-Source'] = `${store.state.system}_dashboard2.0`
  }
  //后续自定义分析 重复请求查询结果的时候要增加一个 上一次请求的traceid
  if (opts.url.includes('/advance/search') && opts.url !== '/advance/search') {
    let curlist = res_Header_arr.find(x => {
      return opts.url.includes(x.requestid)
    })
    service.defaults.headers['R-TraceId'] = curlist.res_Header['r-traceid']
  } else {
    service.defaults.headers['R-TraceId'] = ''
  }

  return new Promise((resolve, reject) => {
    return service(opts1)
      .then(res => {
        if ((opts1.url === '/dashboard/search' || opts1.url === '/advance/search') && res.data.data && res.data.data.requestID) {
          const reqlist = {
            requestid: res.data.data.requestID,
            res_Header: res.headers,
          }
          res_Header_arr.push(reqlist)
        }
        if (!(res && res.data)) return
        resolve(res.data)
      })
      .catch(e => {
        console.error(e)
        if (opts1.url.includes('/bookmark/')) {
          resolve('')
        }
        reject(e)
      })
  })
}
