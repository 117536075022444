import request from '@/utils/request2'
// 用户信息
export function getUserInfo(data) {
  return request({
    url: '/user/info',
    method: 'get',
    data: data,
  })
}

//获取项目绑定的商场
export function getProjectMalls() {
  return request({
    url: '/project/malls',
    method: 'get',
  })
}
export function getMpUserInfo() {
  return request({
    url: '/user/mpinfo',
    method: 'get',
  })
}

// 用户信息
export function getHomeInfo(data) {
  return request({
    url: '/home/info',
    method: 'get',
    data: data,
  })
}

//事件元数据
export function getBehaviorMetadata() {
  return request({
    url: '/behavior/metadata',
    method: 'get',
  })
}
//实体元数据
export function getAttrMetadata() {
  return request({
    url: '/attribute/metadata',
    method: 'get',
  })
}
//筛选条件
export function getFilterOperator(data) {
  return request({
    url: '/metadata/filter/condition/operator',
    method: 'get',
    data: data,
  })
}

// 菜单
export function getMenuLoad() {
  return request({
    url: '/menu',
    method: 'get',
  })
}
// 调整菜单顺序
export function RectifyOrder(param, data) {
  return request(
    {
      url: '/menu/RectifyOrder',
      params: param,
      data: data,
    },
    'post'
  )
}

export function getSharedDashboards(type) {
  return request({
    url: `/menu/${type}`,
    method: 'get',
  })
}

// Search2
export function Search2(data) {
  return request(
    {
      url: `/dashboard/search`,
      data: data,
    },
    'post'
  )
}

export function cardSearch(type, data) {
  return request(
    {
      url: `/${type}/search`,
      data: data,
    },
    'post'
  )
}

//查询
export function getAdvanceSearch2(code) {
  return request({
    url: '/advance/search/' + code,
    method: 'get',
  })
}

// putAxisunit
export function putAxisunit(data) {
  return request(
    {
      url: `/project/axisunit`,
      data: data,
    },
    'put'
  )
}

// 节假日描述
export function getFestival(data) {
  return request({
    url: '/festival/description/summary',
    method: 'get',
    params: data,
  })
}

// 地图
export function getMallMap(data) {
  return request({
    url: '/mall/floor',
    method: 'get',
    params: data,
  })
}

// putTimeRange
export function putV2TimeRange(data) {
  return request({
    url: `/dashboard/timeRange`,
    data: data,
    method: 'put',
  })
}

//筛选条件
export function getFilterOption(data) {
  return request({
    url: '/metadata/filter/condition/option',
    method: 'post',
    data: data,
  })
}

//用户快速检索
export function getUserRetrieval(param, data) {
  return request({
    url: '/user/retrieval',
    method: 'get',
    params: param,
    data: data,
  })
}

// 所有卡片
export function getBookmarkTab(params) {
  return request({
    url: '/dashboard/bookmark/tab',
    method: 'get',
    params: params,
  })
}

export function dashboardExport(data) {
  return request({
    url: '/dashboard/export',
    method: 'post',
    data,
  })
}

export function getMallList() {
  return request({
    url: '/mall/list',
    method: 'get',
  })
}

export function getOrCreateProject(mallID) {
  return request({
    url: '/project?mallID=' + mallID,
    method: 'get',
  })
}

//获取模板
export function getTemplateByCode(code) {
  return request({
    url: `/template?code=${code}`,
    method: 'get',
  })
}

// 获取城市天气
export function getCityWeather() {
  return request({
    url: '/project/cityWeather',
    method: 'get',
  })
}
// 获节假日
export function getFestivals() {
  return request({
    url: '/project/festivals',
    method: 'get',
  })
}

// 添加文件夹
export function addFolder(data) {
  return request({
    url: '/folder',
    data: data,
    method: 'post',
  })
}

// 修改文件夹
export function updateFolder(data) {
  return request({
    url: '/folder',
    data: data,
    method: 'put',
  })
}

// 删除文件夹
export function deleteFolder(folderID) {
  return request({
    url: '/folder?folderID=' + folderID,
    method: 'delete',
  })
}

//添加dashboard
export function addDashboard(data) {
  return request({
    url: 'dashboard',
    data: data,
    method: 'post',
  })
}

//更新 dashborad 名称
export function updataDashboard(data) {
  return request({
    url: '/dashboard',
    data: data,
    method: 'put',
  })
}

//删除dashboard
export function deleteDashboard(dashboardID) {
  return request({
    url: '/dashboard?dashboardID=' + dashboardID,
    method: 'delete',
  })
}

//新建书签
export function postBookmark(data) {
  return request({
    url: '/bookmark',
    data: data,
    method: 'post',
  })
}

// 添加书签
export function addBookMarks(data) {
  return request({
    url: '/dashboard/bookmark',
    data: data,
    method: 'put',
  })
}

//移除书签
export function removeBookmark(param) {
  return request({
    url: '/dashboard/bookmark',
    params: param,
    method: 'delete',
  })
}

//删除书签
export function deleteBookmark(id) {
  return request({
    url: '/bookmark/' + id,
    method: 'delete',
  })
}

export function getBookmarks(type, dashboardID) {
  let url = `/dashboard/bookmarks?dashboardID=${dashboardID}`
  if (type && type != 'bi') url = `/dashboard/${type}/bookmarks?dashboardID=${dashboardID}`
  return request({
    url: url,
    method: 'get',
  })
}

export function getDashboard(dashboardID) {
  return request({
    url: '/dashboard/' + dashboardID,
    method: 'get',
  })
}

//取消锁定仪表盘页面
export function unlockDashboard(param) {
  return request({
    url: '/dashboard/unlock',
    method: 'put',
    params: param,
  })
}

//锁定仪表盘页面
export function lockDashboard(param) {
  return request({
    url: '/dashboard/lock',
    method: 'put',
    params: param,
  })
}

//锁定文件夹
export function lockFolder(param) {
  return request({
    url: '/folder/Lock',
    method: 'put',
    params: param,
  })
}
//取消锁定文件夹
export function unlockFolder(param, data) {
  return request({
    url: '/folder/unLock',
    method: 'put',
    params: param,
  })
}

// 调整Dashboard仪表盘顺序
export function updateBookmarkPosition(data) {
  return request({
    url: '/dashboard/bookmarkPosition',
    method: 'put',
    data: data,
  })
}
// 设置系统颜色
export function SetProjectTheme(data) {
  return request({
    url: '/project/theme',
    data: data,
    method: 'put',
  })
}

export function getNews(data) {
  return request({
    url: '/home/news',
    method: 'get',
    params: data,
  })
}

export function closingNews(newsID) {
  return request({
    url: '/home/news?newsID=' + newsID,
    method: 'post',
  })
}

// 获取API ID
export function getApiId(id) {
  return request({
    url: '/bookmark/apiid/' + id,
    method: 'get',
  })
}

export function getDashboardTrees(data) {
  return request({
    url: '/dashboard/tree',
    method: 'get',
    data: data,
  })
}
// 更新钻取设置
export function putBookmarkDrill(data) {
  return request({
    url: '/dashboard/bookmark/drill',
    method: 'put',
    data,
  })
}
// 切换 钻取状态
export function putDrillSwitch(data) {
  return request({
    url: '/dashboard/bookmark/drill/switch',
    method: 'put',
    data,
  })
}
// 联动启用关闭接口
export function putLinkageSwitch(data) {
  return request({
    url: '/dashboard/bookmark/linkage/switch',
    method: 'put',
    data,
  })
}
// 联动配置接口
export function putLinkage(data) {
  return request({
    url: '/dashboard/bookmark/linkage',
    method: 'put',
    data,
  })
}

// 获取书签
export function getDashboardBookmark(params) {
  return request({
    url: '/dashboard/bookmark',
    method: 'get',
    params,
  })
}

//数据面板维保信息
export function getdashboardmaintInfo() {
  return request({
    url: '/project/maintInfo/dashboard',
    method: 'get',
  })
}

//
export function postbzlog(data) {
  return request({
    url: '/bzlog',
    method: 'post',
    data,
  })
}

export function asyncExport(data) {
  return request({
    url: '/asyncExport/upload',
    method: 'post',
    data,
  })
}

export function getdataexportmode() {
  return request({
    url: '/asyncExport/dataexportmode',
    method: 'get',
  })
}
